import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Edit from 'contentPublisher/components/Edit'

const Admin = (props) => {
  return (
    <Switch>
      <Route path="/systems/new" component={Edit} exact/>
      <Route path="/systems/:id/edit" component={Edit} exact/>
    </Switch>
  )
}

export default Admin
