import React from 'react'
import {
  styled,
  Chip as MuiChip,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useContentTypeState, useEditApplication } from 'contentPublisher/hooks'

const Dialog = styled(MuiDialog)({})

const CloseButton = styled(MuiIconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
})

const DialogContent = styled(MuiDialogContent)({})

const Chip = styled(MuiChip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const AddDialog = ({ onClose, ...props }) => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useContentTypeState()

  const appContentTypes = application.content || []

  const addContentType = id => {
    const set = new Set(appContentTypes)
    set.add(id)
    updateApplication({ content: Array.from(set) })
  }

  const contentTypes = data.allIds
    .filter(id => !appContentTypes.includes(id))
    .map(id => data.byId[id])
    .filter(i => !i.hidden)
    .sort(nameSorter)

  return (
    <Dialog fullWidth scroll='body' onClose={onClose} {...props}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <MuiDialogTitle>Content Type (click to add)</MuiDialogTitle>
      <DialogContent>
        {contentTypes.map(contentType => {
          return (
            <Chip
              size='small'
              key={contentType.id}
              label={contentType.name}
              color='primary'
              onClick={() => addContentType(contentType.id)}
            />
          )
        })}
      </DialogContent>
    </Dialog>
  )
}

export default AddDialog
