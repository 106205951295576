import React from 'react'
import { useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Paper as MuiPaper,
} from '@material-ui/core'
import { openReportDialog } from 'reports/store/actions'
import { useCounts } from 'reports/hooks'
import Loader from 'ui/components/Loader'
import Counts from 'reports/components/Counts'

const Content = styled(MuiPaper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 24,
  color: theme.palette.text.secondary,
}))

const ActionButtonContainer = styled('div')({
  display: 'flex',
})

const Searches = props => {
  const { searches } = useCounts()
  const dispatchRedux = useDispatch()

  const areSearchesFetched =
    searches && searches.total !== undefined && searches.total !== null

  const openGeneralReportDialog = () =>
    dispatchRedux(openReportDialog('searches'))
  const openPopularReportDialog = () =>
    dispatchRedux(openReportDialog('popularSearches'))
  const openContentPubliherDialog = () =>
    dispatchRedux(openReportDialog('contentPublisherCount'))

  const content = areSearchesFetched ? <Counts data={searches} /> : <Loader />

  return (
    <Content {...props}>
      <Header>
        <Title>Searches</Title>
        <ActionButtonContainer>
          <MuiButton
            size='small'
            color='primary'
            onClick={openContentPubliherDialog}
          >
            Content Publisher
          </MuiButton>
          <MuiButton
            size='small'
            color='primary'
            onClick={openPopularReportDialog}
          >
            Popular Report
          </MuiButton>
          <MuiButton
            size='small'
            color='primary'
            onClick={openGeneralReportDialog}
          >
            Generate Report
          </MuiButton>
        </ActionButtonContainer>
      </Header>
      {content}
    </Content>
  )
}

export default Searches
