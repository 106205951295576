import * as yup from 'yup'
import PhoneNumber from 'awesome-phonenumber'

export const firstNameSchema = yup
  .string()
  .label('First name')
  .required('You must provide a first name')

export const lastNameSchema = yup
  .string()
  .label('Last name')
  .required('You must provide a last name')

export const passwordSchema = yup
  .string()
  .label('Password')
  .required('You must create a password')

export const emailSchema = yup
  .string()
  .label('Email')
  .required('You must provide a valid email address')
  .email('You must provide a valid email address')

export const phoneSchema = yup
  .string()
  .label('Phone')
  .required('You must provide a valid phone number')
  .test('phoneNumber', 'You must provide a valid phone number', value => {
    const number = value || ''

    let valid = false
    PhoneNumber.getSupportedRegionCodes().forEach(code => {
      if (PhoneNumber(number, code).isValid()) valid = true
    })

    return valid
  })

export const companyNameSchema = yup
  .string()
  .label('Company Name')
  .required('You must provide a company name')

export const jobTitleSchema = yup
  .string()
  .label('Job Title')
  .required('You must provide a job title')

export const citySchema = yup
  .string()
  .label('City')
  .required('You must provide a city')

export const stateSchema = yup
  .string()
  .label('State/province')
  .required('You must provide a state/province')

export const countrySchema = yup
  .string()
  .label('Country')
  .required('You must provide a country')

export const usageTypeSchema = yup
  .string()
  .label('How do you plan to use FindContent?')
  .required('You must provide an answer')

export const userSchema = yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phone: phoneSchema,
  password: passwordSchema,
  companyName: companyNameSchema,
  jobTitle: jobTitleSchema,
  city: citySchema,
  state: stateSchema,
  country: countrySchema,
  usageType: usageTypeSchema,
})

export const profileUserSchema = yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phone: phoneSchema,
  companyName: companyNameSchema,
  jobTitle: jobTitleSchema,
  city: citySchema,
  state: stateSchema,
  country: countrySchema,
})
