import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as yup from 'yup'
import {styled} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {CircularProgress} from '@material-ui/core'
import {updatePassword} from 'users/store/actions'
import {usePasswordState} from 'users/hooks'

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 6,
})

const Subtitle = styled('h4')({
  fontSize: 16,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 18,
})

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
})

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center',
})

const passwordSchema = yup.string()
  .label("Password")
  .min(4)
  .required("A password is required")

const PasswordReset = ({token, source, ...props}) => {
  const {status} = usePasswordState()
  const [password, setPassword] = useState()
  const [showErrors, setShowErrors] = useState(false)
  const dispatchRedux = useDispatch()

  let passwordError
  try {
    passwordSchema.validateSync(password)
  } catch (error) {
    passwordError = error.message
  }

  const handlePasswordChange = e => setPassword(e.target.value)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (passwordError) {
      setShowErrors(true)
    } else {
      dispatchRedux(updatePassword({token, password}))
    }
  }

  let disableButton
  if (status === 'started') disableButton = true

  let title = "Password Reset"
  let subtitle = "Enter your new password"
  let label = "Enter a password"
  let buttonText = "Update Password"

  if (source === 'welcome') {
    title = "Complete Your Registration"
    subtitle = "Create a password to finalize registration"
    label = "Create a password"
    buttonText = "Complete Registration"
  }

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Form onSubmit={handleSubmit} {...props}>
        <TextField
          label={label}
          type="password"
          defaultValue=""
          onChange={handlePasswordChange}
          error={showErrors && !!passwordError}
          helperText={showErrors && passwordError}
          variant="outlined"
          margin="dense"
          fullWidth
          autoFocus
        />
      </Form>
      <Actions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={disableButton}
        >
          {buttonText}
        </Button>
        {disableButton &&
          <CircularProgress color="secondary" style={{height: '25px', width: '25px', marginLeft: '25px', marginTop: '5px' }}/>
        }
      </Actions>
    </>
  )
}

export default PasswordReset
