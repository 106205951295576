import React, { useState } from 'react'
import debounce from 'lodash/debounce'
import Fuse from 'fuse.js'
import moment from 'moment'
import {
  styled,
  withStyles,
  Fab as MuiFab,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow
} from '@material-ui/core'
import MuiAddIcon from '@material-ui/icons/Add'
import { useUsers } from 'users/hooks'
import RoleFilter from './RoleFilter'
import SortBy from './SortBy'
import Search from './Search'

const Content = styled(MuiPaper)({
  marginTop: 20,
  marginBottom: 20
})

const Header = styled('div')({
  padding: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
})

const Title = styled('h2')(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 0
}))

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

const Table = styled(MuiTable)({})

const Fab = withStyles(theme => ({
  label: {
    marginRight: 8
  }
}))(MuiFab)

const UserRow = styled(MuiTableRow)({
  cursor: 'pointer'
})

const TableCell = withStyles(theme => ({
  root: {},
  head: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12
  },
  body: {
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 18,
    paddingRight: 12
  }
}))(MuiTableCell)

const NameCell = withStyles(theme => ({
  root: {
    whiteSpace: 'nowrap'
  }
}))(TableCell)

const EmailCell = withStyles(theme => ({
  body: {
    fontSize: 12
  }
}))(TableCell)

const LastSignedInCell = withStyles(theme => ({}))(TableCell)

const RoleCell = withStyles(theme => ({}))(TableCell)

const Name = styled('div')({
  fontWeight: 500
})

const JobTitle = styled('div')({
  fontSize: 12,
  fontStyle: 'italic'
})

const CompanyName = styled('div')({
  fontSize: 12
})

const lastNameSorter = (a, b) => a.lastName.localeCompare(b.lastName)
const recentSignInSorter = (a, b) => {
  if (a.lastSignedIn && b.lastSignedIn) {
    if (a.lastSignedIn > b.lastSignedIn) return -1
    if (a.lastSignedIn < b.lastSignedIn) return 1
  } else {
    return b.lastSignedIn ? 1 : a.lastSignedIn ? -1 : 0
  }
}
const latestSignInSorter = (a, b) => {
  if (a.lastSignedIn && b.lastSignedIn) {
    if (a.lastSignedIn > b.lastSignedIn) return 1
    if (a.lastSignedIn < b.lastSignedIn) return -1
  } else {
    return b.lastSignedIn ? -1 : a.lastSignedIn ? 1 : 0
  }
}

const fuseOptions = {
  id: 'id',
  shouldSort: true,
  threshold: 0.25,
  keys: ['firstName', 'lastName', 'companyName', 'email']
}

const List = ({ history, ...props }) => {
  const { data } = useUsers()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [role, setRole] = useState('all')
  const [sortBy, setSortBy] = useState('lastName')
  const [search, setSearch] = useState('')

  // if (data.allIds.length !== data.total) return null

  const begin = page * rowsPerPage
  const end = begin + rowsPerPage

  let users = data.allIds.map(id => data.byId[id]).sort(lastNameSorter)

  if (sortBy === 'recentSignIn') users.sort(recentSignInSorter)
  if (sortBy === 'lastSignIn') users.sort(latestSignInSorter)

  switch (role) {
    case 'admins':
      users = users.filter(u => u.role === 'admin')
      break

    case 'vendors':
      users = users.filter(u => u.role === 'vendor')
      break

    case 'users':
      users = users.filter(u => u.role === 'user')
      break

    // no default
  }

  if (search) {
    const fuse = new Fuse(users, fuseOptions)
    const searchResults = fuse.search(search)

    users = users.filter(u =>
      searchResults.some(searchResult => searchResult.item.id === u.id)
    )
  }

  const paginatedUsers = users.slice(begin, end)

  const handlePageChange = (event, number) => setPage(number)

  const rowsPerPageOptions = [25, 50, 100]
  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const userRows = paginatedUsers.map(user => {
    const fullName = `${user.firstName} ${user.lastName}`
    const role = user.role.replace(/./, w => w.toUpperCase())

    const lastSignedIn = user.lastSignedIn
      ? moment(user.lastSignedIn).calendar()
      : null

    return (
      <UserRow
        key={user.id}
        hover
        onClick={() => history.push(`/users/${user.id}/edit`)}
      >
        <NameCell>
          <Name>{fullName}</Name>
          <JobTitle>{user.jobTitle}</JobTitle>
          <CompanyName>{user.companyName}</CompanyName>
        </NameCell>
        <EmailCell>{user.email}</EmailCell>
        <LastSignedInCell>{lastSignedIn}</LastSignedInCell>
        <RoleCell>{role}</RoleCell>
      </UserRow>
    )
  })

  const handleSearchChange = debounce(value => {
    setPage(0)
    setSearch(value)
  }, 500)

  return (
    <Content square>
      <Header>
        <Title>Users</Title>
        <Actions>
          <Search onChange={handleSearchChange} />
          <SortBy value={sortBy} setValue={setSortBy} />
          <RoleFilter role={role} setRole={setRole} />
          <Fab
            color='primary'
            size='small'
            variant='extended'
            onClick={() => history.push(`/users/new`)}
          >
            <MuiAddIcon size='small' />
            Add User
          </Fab>
        </Actions>
      </Header>
      <Table size='small'>
        <MuiTableHead>
          <MuiTableRow>
            <NameCell>Name</NameCell>
            <EmailCell>Email</EmailCell>
            <LastSignedInCell>Last Signed In</LastSignedInCell>
            <RoleCell>Role</RoleCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>{userRows}</MuiTableBody>
        <MuiTableFooter>
          <MuiTableRow>
            <MuiTablePagination
              count={users.length}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </MuiTableRow>
        </MuiTableFooter>
      </Table>
    </Content>
  )
}

export default List
