import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useRegistrationState } from 'users/hooks'
import { createUser, resetUserState } from 'users/store/actions'
import CountrySelect from 'ui/components/CountrySelect'
import { initializeState, reducer, updateField, showError } from './state'
import Legal from './Legal'
import {
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'

const fields = [
  {
    key: 'firstName',
    label: 'First name',
    type: 'text',
    required: true,
    autocomplete: 'given-name',
  },
  {
    key: 'lastName',
    label: 'Last name',
    type: 'text',
    required: true,
    autocomplete: 'family-name',
  },
  {
    key: 'email',
    label: 'Email',
    type: 'email',
    required: true,
    autocomplete: 'email',
  },
  { key: 'password', label: 'Password', type: 'password', required: true },
  {
    key: 'phone',
    label: 'Phone',
    type: 'tel',
    required: true,
    autocomplete: 'tel',
  },
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'text',
    required: true,
    autocomplete: 'organization',
  },
  {
    key: 'jobTitle',
    label: 'Job Title',
    type: 'text',
    required: true,
    autocomplete: 'organization-title',
  },
  {
    key: 'city',
    label: 'City',
    type: 'text',
    required: true,
    autocomplete: 'address-level2',
  },
  {
    key: 'state',
    label: 'State/Province',
    type: 'text',
    required: true,
    autocomplete: 'address-level1',
  },
  {
    key: 'country',
    label: 'Country',
    type: 'countrySelect',
    required: true,
    autocomplete: 'country-name',
  },
  {
    key: 'usageType',
    label: 'How do you plan to use FindContent?',
    type: 'usageType',
    required: true,
    autocomplete: 'usage-type',
  },
  {
    key: 'everPurchased',
    label: 'Have you ever purchased, 3rd party content before?',
    type: 'everPurchased',
    required: true,
    autocomplete: 'ever-purchased',
  },
  {
    key: 'contentNeeded',
    label: 'Do you need content for your learning system?',
    type: 'contentNeeded',
    autocomplete: 'purchase-learning-system',
  },
]

const Content = styled('div')({})

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 6,
})

const Subtitle = styled('h4')({
  fontSize: 16,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 18,
})

const Form = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 8px 1fr',

  // default text fields
  '& > *': {
    gridColumn: '1 / 4',
  },

  [theme.breakpoints.up('md')]: {
    // first name field
    '& > :nth-child(1)': {
      gridColumn: '1',
    },

    // last name field
    '& > :nth-child(2)': {
      gridColumn: '3',
    },

    // city field
    '& > :nth-child(7)': {
      gridColumn: '1',
    },

    // state field
    '& > :nth-child(8)': {
      gridColumn: '3',
    },
  },
}))

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledLegal = styled(Legal)({
  flex: '0 1 auto',
})

const SignUpButton = styled(Button)({
  flex: '0 0 auto',
  marginLeft: 20,
})

const AlreadyRegistered = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: 0,
  padding: 5,
}))

const AlreadyRegisteredLink = styled('span')({
  color: '#0000EE',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
})

const Signup = ({ props, ...rest }) => {
  const { status: regStatus, errors: regErrors } = useRegistrationState()
  const [state, dispatch] = useReducer(reducer, {}, initializeState)
  const { user, errors, showErrors } = state

  const dispatchRedux = useDispatch()

  const goToLoginPage = page => {
    props.history.push('/login')
    dispatchRedux(resetUserState())
  }

  const goToForgotPasswordPage = page => {
    props.history.push('/password')
    dispatchRedux(resetUserState())
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (Object.keys(errors).length > 0) {
      fields.forEach(f => dispatch(showError(f.key)))
    } else {
      dispatchRedux(createUser({ ...user, isVerified: true }, true))
    }
  }
  const isEmailAlreadyRegistered =
    regStatus === 'conflict' && regErrors && regErrors.email === 'value'
  return (
    <Content {...props}>
      <Title>Sign up</Title>
      <Subtitle>Get started with your free account</Subtitle>
      <Form onSubmit={handleSubmit}>
        {fields.map(({ key, label, type, required, autocomplete }, i) => {
          const autoFocus = i === 0
          const defaultValue = user[key]
          let hasError = !!errors[key] && showErrors.includes(key)
          let errorMessage = errors[key]

          if (
            key === 'email' &&
            regStatus === 'conflict' &&
            defaultValue === regErrors.email
          ) {
            hasError = true
            errorMessage =
              errors[key] || 'That email address has already been registered'
          }

          const handleChange = e => dispatch(updateField(key, e.target.value))
          const handleBlur = () => {
            if (Object.keys(user).length > 0) dispatch(showError(key))
          }

          if (type === 'countrySelect') {
            return (
              <CountrySelect
                key={key}
                value={defaultValue}
                onChange={handleChange}
                error={hasError}
                errorMessage={errorMessage}
              />
            )
          }

          if (type === 'contentNeeded') {
            return (
              <MuiFormControl
                key={key}
                variant='outlined'
                margin='dense'
                error={hasError}
                required={required}
              >
                <MuiInputLabel htmlFor='contentNeeded'>
                  {label}
                </MuiInputLabel>
                <MuiSelect
                  name='contentNeeded'
                  value={defaultValue || ''}
                  onChange={handleChange}
                  labelWidth={332}
                >
                  <MuiMenuItem value={'no'}>No</MuiMenuItem>
                  <MuiMenuItem value={'yes'}>Yes</MuiMenuItem>
                  <MuiMenuItem value={'noLearningSystem'}>We do not have a learning system</MuiMenuItem>
                </MuiSelect>
              </MuiFormControl>
            )
          }
          if (type === 'usageType') {
            const hasSelectedOther = defaultValue === 'other'
            const handleOtherInputChange = e =>
              dispatch(updateField('usageTypeExplanation', e.target.value))
            const otherInputValue = user['usageTypeExplanation'] || ''
            return (
              <MuiFormControl
                key={key}
                variant='outlined'
                margin='dense'
                required={required}
                error={hasError}
              >
                <MuiInputLabel htmlFor='usageType'>{label}</MuiInputLabel>
                <MuiSelect
                  name='usageType'
                  value={defaultValue || ''}
                  onChange={handleChange}
                  labelWidth={275}
                >
                  <MuiMenuItem value={'justLooking'}>Just looking</MuiMenuItem>
                  <MuiMenuItem value={'researchingContentProviders'}>
                    Research
                  </MuiMenuItem>
                  <MuiMenuItem value={'comparing'}>
                    Seeking to compare content publishers
                  </MuiMenuItem>
                  <MuiMenuItem value={'planningToBuy'}>
                    Plan to buy 3rd party content/courses
                  </MuiMenuItem>
                  <MuiMenuItem value={'findPublisher'}>
                    Find a specific publisher of interest
                  </MuiMenuItem>
                  <MuiMenuItem value={'other'}>Other</MuiMenuItem>
                </MuiSelect>
                {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
                {hasSelectedOther && (
                  <TextField
                    key={key}
                    label={'Please explain how do you plan to use FindContent'}
                    type={type}
                    defaultValue={otherInputValue}
                    onChange={handleOtherInputChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    autoComplete={autocomplete}
                    error={hasError}
                    helperText={hasError && errorMessage}
                  />
                )}
              </MuiFormControl>
            )
          }
          if (type === 'everPurchased') {
            return (
              <MuiFormControl
                key={key}
                variant='outlined'
                margin='dense'
                error={hasError}
                required={required}
              >
                <MuiInputLabel htmlFor='everPurchased'>{label}</MuiInputLabel>
                <MuiSelect
                  name='everPurchased'
                  value={defaultValue || ''}
                  onChange={handleChange}
                  labelWidth={377}
                  error={hasError}
                >
                  <MuiMenuItem value={'yes'}>Yes</MuiMenuItem>
                  <MuiMenuItem value={'no'}>No</MuiMenuItem>
                  <MuiMenuItem value={'unsure'}>
                    Unsure
                  </MuiMenuItem>
                </MuiSelect>
              </MuiFormControl>
            )
          }

          return (
            <TextField
              key={key}
              label={label}
              type={type}
              defaultValue={defaultValue}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
              margin='dense'
              fullWidth
              required={required}
              autoComplete={autocomplete}
              error={hasError}
              helperText={hasError && errorMessage}
              autoFocus={autoFocus}
            />
          )
        })}
      </Form>
      {isEmailAlreadyRegistered && (
        <AlreadyRegistered>
          Already registered. Please{' '}
          <AlreadyRegisteredLink onClick={goToLoginPage}>
            login
          </AlreadyRegisteredLink>
          . If you forgotten your password, you can reset it{' '}
          <AlreadyRegisteredLink onClick={goToForgotPasswordPage}>
            here
          </AlreadyRegisteredLink>
          .
        </AlreadyRegistered>
      )}
      <Actions>
        <StyledLegal />
        <SignUpButton
          onClick={handleSubmit}
          color='primary'
          variant='contained'
        >
          Sign Up
        </SignUpButton>
      </Actions>
    </Content>
  )
}

export default Signup
