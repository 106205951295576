import React from 'react'
import { useCompareData } from './hooks'
import { Section, Subsection } from './Sections'
import Row from './Row'
import Values from './Values'

const KeySpecs = props => {
  const {
    systems,
    contentTypes,
    durations,
    categories,
    deliveryTypes,
    languages,
    // publishers,
  } = useCompareData()

  return (
    <>
      <Section>Key Specs</Section>
      <Subsection>Content Types</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.content
            .filter(id => Boolean(contentTypes.byId[id]))
            .map(id => contentTypes.byId[id] && contentTypes.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>
      <Subsection>Durations</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.duration
            .filter(id => Boolean(durations.byId[id]))
            .map(id => durations.byId[id] && durations.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>

      <Subsection>Categories</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.subject
            .filter(id => Boolean(categories.byId[id]))
            .map(id => categories.byId[id] && categories.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>

      <Subsection>Delivery Types</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.delivery
            .filter(id => Boolean(deliveryTypes.byId[id]))
            .map(id => deliveryTypes.byId[id] && deliveryTypes.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>

      <Subsection>Languages</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.languages
            .filter(id => Boolean(languages.byId[id]))
            .map(id => languages.byId[id] && languages.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>
      {/* <Subsection>Publishers</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.publishers
            .filter(id => Boolean(publishers.byId[id]))
            .map(id => publishers.byId[id] && publishers.byId[id].name)
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row> */}
    </>
  )
}

export default KeySpecs
