import React, { useState } from 'react'
import {
  styled,
  TextField as MuiTextField,
  Chip as MuiChip
} from '@material-ui/core'
import { useEditApplication } from 'contentPublisher/hooks'
// import AddDialog from './AddDialog'

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  gridColumnGap: 20,
})

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

const Actions = styled('div')({})

const Chip = styled(MuiChip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  // marginBottom: theme.spacing(1),
}))

const ChipButton = styled(Chip)({
  cursor: 'pointer',
})

const Content = styled('div')({
  fontSize: 14,
  margin: '0.75rem 0',
})

const InstilledPlayed = ({ mode, id }) => {
  // const [showDialog, setShowDialog] = useState(false)
  const [application, updateApplication] = useEditApplication()
  const { instilledPlayer } = application
  const labelText = instilledPlayer ? 'Edit Content on Instilled' : 'Instilled Login'

  const updateInstilledPlayerLink = (change) => updateApplication({ instilledPlayer: change })

  return (
    <>
      <Heading>
        <Title>Instilled Content Playlist</Title>
        {/* <Actions>
          <AddDialog open={showDialog} onClose={() => setShowDialog(false)} />
          <ChipButton
            label='Learn More'
            size='small'
            color='primary'
            onClick={() => setShowDialog(true)}
          />
        </Actions> */}
      </Heading>
      <Content>Contact the administrator to set up the Instilled Content Playlist URL for your account.</Content>
      <Grid>
        <MuiTextField
          defaultValue={instilledPlayer}
          label="Content Playlist URL"
          variant="outlined"
          margin="dense"
          disabled={mode !== "admin"}
          onChange={(e) => updateInstilledPlayerLink(e.target.value)}
          helperText="Example: https://findcontent.instilled.com/player/container/34234234234"
        />
        <Actions>
          <ChipButton
            label={labelText}
            size='small'
            color='primary'
            
            onClick={() => {
              if (instilledPlayer) {
                const containerId = instilledPlayer.split('/')[5];
                window.open(`https://findcontent.instilled.com/containers/${containerId}`, '_blank')
              } else {
                window.open(`https://findcontent.instilled.com/`, '_blank')
              }

            }}
          />
        </Actions>
      </Grid>
    </>
  )
}

export default InstilledPlayed
