import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useContentTypeState } from 'contentPublisher/hooks'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')({})

const Value = styled('li')({})

const ContentTypes = ({ content = [] }) => {
  const { data } = useContentTypeState()

  if (content.length === 0) return null
  const checkForContentTypesVisibility = cs => !HIDDEN_FEATURES.includes(cs.name)
  const contentTypesToRender = content.filter(id => {
    const contentTypeFromId = data.byId[id]
    const isVisible =
    contentTypeFromId &&
    checkForContentTypesVisibility(contentTypeFromId)
    return Boolean(contentTypeFromId) && isVisible
  })
  return (
    <>
      <SectionTitle>Content Types</SectionTitle>
      <List>
        {contentTypesToRender.map(id => (
          <Value key={id}>{data.byId[id].name}</Value>
        ))}
      </List>
    </>
  )
}

export default ContentTypes
