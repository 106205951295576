import React from 'react'
import {withRouter} from 'react-router-dom'
import MuiButton from '@material-ui/core/Button'
import {useDispatch} from 'react-redux'
import {saveApplication} from 'contentPublisher/store/actions'
import {useEditApplication} from 'contentPublisher/hooks'

const SaveButton = ({history, ...props}) => {
  const [application] = useEditApplication()
  const dispatchRedux = useDispatch()

  const handleUpdate = () => {
    dispatchRedux(saveApplication())
    history.goBack()
  }

  const title = application.id ? "Update" : "Create"

  return (
    <MuiButton
      color="primary"
      variant="contained"
      onClick={handleUpdate}
    >
      {title}
    </MuiButton>
  )
}

export default withRouter(SaveButton)
