import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))

const DeploymentType = props => {
  const { deploymentType } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateTrialData({ deploymentType: e.target.value }))

  const error = !deploymentType

  return (
    <Section
      title='Do you want a SaaS system or a self-hosted deployment?'
      error={error}
      {...props}
    >
      <RadioGroup value={deploymentType || ''} onChange={handleChange}>
        <MuiFormControlLabel value='SaaS' label='SaaS' control={<MuiRadio />} />
        <MuiFormControlLabel
          value='Self-Hosted'
          label='Self-Hosted'
          control={<MuiRadio />}
        />
      </RadioGroup>
    </Section>
  )
}

export default DeploymentType
