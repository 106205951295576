import reducer from './reducer'
import rootSaga from './sagas'

const getTrialsModule = () => ({
  id: 'trials',
  reducerMap: {
    trials: reducer,
  },
  sagas: [rootSaga],
  initialActions: [],
  finalActions: [],
})

export default getTrialsModule
