import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import { actionTypes as contentPublisherActionTypes } from 'contentPublisher/store/constants'
import { actionTypes as userActionTypes } from 'users/store/constants'
import { actionTypes as trialActionTypes } from 'trials/store/constants'
import { actionTypes } from './constants'
import { getCurrentUser } from 'users/store/selectors'
import { getMode } from 'app/store/selectors'
import { fetchLeads } from './actions'

const leadsService = feathersClient.service('leads')

function* generateLeadsSaga(action) {
  const state = yield select()
  const user = getCurrentUser(state)
  const mode = getMode(state)

  // don't generate leads for admins & vendors
  if (mode !== 'user') return

  // fetch user's leads
  const { data } = yield call([leadsService, 'find'], {
    query: { version: 2, user, $limit: 200 },
  })

  let appIds, trigger, comparedSystems
  switch (action.type) {
    case contentPublisherActionTypes.VIEW_CONTENT_PROVIDER_DETAILS:
      appIds = [action.payload.id]
      trigger = 'Details Viewed'
      break
    case contentPublisherActionTypes.VIEW_APPLICATION_CONTACT_INFO:
      appIds = [action.payload.id]
      trigger = 'Contact Info Viewed'
      break
    case contentPublisherActionTypes.WEBSITE_LINK_VISITED:
      appIds = [action.payload.id]
      trigger = 'Website Visited'
      break
    case userActionTypes.ADD_LMS_TO_LIST:
      appIds = action.payload
      trigger = 'Added to Favorites'
      break
    case trialActionTypes.CREATE_TRIAL_SUCCEEDED:
      appIds = [action.payload.systemId]
      trigger = 'Requested Trial'
      break
    case contentPublisherActionTypes.COMPARE_SYSTEMS:
      appIds = action.payload.ids ? [...action.payload.ids] : []
      trigger = 'Compared'
      comparedSystems = action.payload.ids ? [...action.payload.ids] : []
      break
    // no default
  }

  for (const appId of appIds) {
    let lead = data.find(l => l.application === appId)
    let systemsBeingCompared
    if (comparedSystems) {
      systemsBeingCompared = comparedSystems.filter(id => id !== appId)
    }

    if (!lead) {
      // create new lead
      lead = yield call([leadsService, 'create'], {
        version: 2,
        user,
        application: appId,
        otherSystems: comparedSystems ? systemsBeingCompared : null,
      })
    }
    // update lead with new action
    lead = yield call([leadsService, 'patch'], lead, {
      $addToSet: {
        actions: {
          trigger,
          otherSystems: comparedSystems ? systemsBeingCompared : null,
        },
      },
    })
  }
}

function* fetchLeadsSaga(action) {
  const { limit, skip } = action.payload

  try {
    const results = yield call([leadsService, 'find'], {
      query: { version: 2, $limit: limit, $skip: skip },
    })

    const fetchedCount = skip + limit
    const completed = fetchedCount >= results.total

    yield put({
      type: actionTypes.FETCH_LEADS_SUCCEEDED,
      payload: { results, completed },
    })

    if (!completed) yield put(fetchLeads({ limit, skip: fetchedCount }))
  } catch (error) {
    yield put({ type: actionTypes.FETCH_LEADS_FAILED, payload: { error } })
  }
}

function* watchGenerateLeads() {
  const leadGenerators = [
    contentPublisherActionTypes.VIEW_CONTENT_PROVIDER_DETAILS,
    contentPublisherActionTypes.VIEW_APPLICATION_CONTACT_INFO,
    contentPublisherActionTypes.WEBSITE_LINK_VISITED,
    userActionTypes.ADD_LMS_TO_LIST,
    trialActionTypes.CREATE_TRIAL_SUCCEEDED,
    contentPublisherActionTypes.COMPARE_SYSTEMS,
  ]

  yield takeEvery(leadGenerators, generateLeadsSaga)
}

function* watchFetchLeads() {
  yield takeLatest(actionTypes.FETCH_LEADS_REQUESTED, fetchLeadsSaga)
}

function* rootSaga() {
  yield all([watchGenerateLeads(), watchFetchLeads()])
}

export default rootSaga
