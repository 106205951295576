import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiDivider from '@material-ui/core/Divider'

const Title = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 24,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

const Divider = styled(MuiDivider)({
  marginBottom: 12,
})

const SectionTitle = ({children}) => {
  return (
    <>
      <Title>{children}</Title>
      <Divider/>
    </>
  )
}

export default SectionTitle
