import React, { useState } from 'react'
import { styled, Chip as MuiChip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useSubjectTypesState, useEditApplication } from 'contentPublisher/hooks'
import AddDialog from './AddDialog'

const Content = styled('div')({})

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

const Actions = styled('div')({})

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const Chip = styled(MuiChip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const ChipButton = styled(Chip)({
  cursor: 'pointer',
})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const SubjectTypes = props => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useSubjectTypesState()
  const [showDialog, setShowDialog] = useState(false)


  const appSubjectStypes = application.subject || []

  const removeSubjectType = id => {
    const set = new Set(appSubjectStypes)
    set.delete(id)
    updateApplication({ subject: Array.from(set) })
  }

  const selectedSubjectTypes = appSubjectStypes
    .map(id => data.byId[id])
    .sort(nameSorter)

  let emptyChip
  if (selectedSubjectTypes.length === 0)
    emptyChip = <Chip size='small' label='No subjects types selected' />

  return (
    <Content {...props}>
      <Heading>
        <Title>Category</Title>
        <Actions>
          <AddDialog open={showDialog} onClose={() => setShowDialog(false)} />
          <ChipButton
            label='Add Category Type'
            size='small'
            color='primary'
            icon={<AddIcon />}
            onClick={() => setShowDialog(true)}
          />
        </Actions>
      </Heading>
      <Grid>
        {selectedSubjectTypes.map(subjectType => {
          return (
            <Chip
              size='small'
              key={subjectType.id}
              label={subjectType.name}
              onDelete={() => removeSubjectType(subjectType.id)}
            />
          )
        })}
        {emptyChip}
      </Grid>
    </Content>
  )
}

export default SubjectTypes
