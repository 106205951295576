import { actionTypes } from './constants'

export const openTrialDialog = systemId => ({
  type: actionTypes.OPEN_TRIAL_DIALOG,
  payload: {
    systemId,
  },
})

export const closeTrialDialog = () => ({
  type: actionTypes.CLOSE_TRIAL_DIALOG,
})

export const updateTrialData = data => ({
  type: actionTypes.UPDATE_TRIAL_DATA,
  payload: data,
})

export const createTrial = () => ({
  type: actionTypes.CREATE_TRIAL_REQUESTED,
})
