import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { initialFilters } from 'searches/store/constants'
import { useCurrentUser } from 'users/hooks'
import MuiPaper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'

import Keywords from 'searches/components/Keywords'
import ContentTypes from 'searches/components/ContentTypes'
import Durations from 'searches/components/Durations'
import SubjectTypes from 'searches/components/Categories'
import DeliveryTypes from 'searches/components/DeliveryTypes'
import CourseStandards from 'searches/components/CourseStandards'
import Features from 'searches/components/Features'
import Languages from 'searches/components/Languages'
import Publishers from 'searches/components/Publishers'

const Content = styled(MuiPaper)(({ theme }) => ({
  maxWidth: 400,
  padding: 10,
}))

const Heading = styled('div')({
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled('h4')({
  margin: 0,
  fontSize: 20,
  fontWeight: 500,
})

const Filters = props => {
  const dispatchRedux = useDispatch()
  const currentUser = useCurrentUser()
  const isGuestUser = !currentUser

  const clearFilters = () =>
    dispatchRedux(updateCurrentSearchFilters(initialFilters))

  return (
    <Content {...props}>
      <Heading>
        <Title>Filters</Title>
        <MuiButton size='small' onClick={clearFilters}>
          Clear
        </MuiButton>
      </Heading>
      <Keywords />
      <ContentTypes />
      <Durations />
      <Features />
      <SubjectTypes />
      <DeliveryTypes />
      <CourseStandards />
      <Languages />
      {/* <Publishers /> */}
    </Content>
  )
}

export default Filters
