import React, { useState } from 'react'
import { styled } from '@material-ui/core/styles'
import MuiBeenhereIcon from '@material-ui/icons/Beenhere'
import Logo from 'contentPublisher/components/Logo'
import ContactInfo from './ContactInfo'
import TrialButton from 'trials/components/Button'
import WebsiteLink from './WebsiteLink'
import SaveButton from 'contentPublisher/components/SaveButton'
import { viewApplicationContactInfo } from 'contentPublisher/store/actions'
import { Button as MuiButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'

const Content = styled('div')({
  display: 'flex',
})

const StyledLogo = styled(Logo)({
  marginRight: 48,
})

const Details = styled('div')({
  flexGrow: 1,
  marginTop: 20,
})

const Info = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})

const Company = styled('div')({
  marginRight: 24,
  flexGrow: 1,
})

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: 24,
})

const NameAndRank = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
})

const Name = styled('h2')(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 0,
  fontSize: 24,
  marginRight: theme.spacing(1),
}))

const Badges = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  marginRight: theme.spacing(2),
}))

const Featured = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.light,
  marginLeft: theme.spacing(2),
}))

const FeaturedText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
}))

const FeaturedIcon = styled(MuiBeenhereIcon)(({ theme }) => ({
  fontSize: 18,
  marginRight: '.28em',
  color: theme.palette.primary.dark,
}))

const CompanyName = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 500,
}))

const Country = styled('p')({
  margin: 0,
})

const StyledTrialButton = styled(TrialButton)(({ theme }) => ({
  marginLeft: -theme.spacing(1),
}))

const Header = ({ contentPublisher, ...props }) => {
  const { id, thumbnail, name, company, country, isPremium, trial } = contentPublisher

  const [expanded, setExpanded] = useState(false)
  const dispatchRedux = useDispatch()

  const handleClick = () => {
    setExpanded(true)
    dispatchRedux(viewApplicationContactInfo(contentPublisher.id))
  }

  let featured, trialButton
  if (isPremium) {
    featured = (
      <Featured>
        <FeaturedIcon />
        <FeaturedText>Premium</FeaturedText>
      </Featured>
    )
  }
  trialButton = <StyledTrialButton systemId={id} />

  return (
    <Content {...props}>
      <StyledLogo thumbnail={thumbnail} />
      <Details>
        <NameAndRank>
          <Name>{name}</Name>
          <Badges>{featured}</Badges>
          <SaveButton contentPublisher={contentPublisher} />
        </NameAndRank>
        <Info>
          <Company>
            <CompanyName>{company}</CompanyName>
            <Country>{country}</Country>
            <WebsiteLink contentPublisher={contentPublisher} />
            <ContactInfo
              contentPublisher={contentPublisher}
              expanded={expanded}
            />
            <MuiButton
              color='primary'
              variant='contained'
              onClick={handleClick}
              style={{ marginRight: '1.5rem', boxShadow: 'none' }}
              disabled={expanded}
            >
              Contact Us
            </MuiButton>
            {trial && trialButton}
          </Company>
          <Grid>{/* More stuff here */}</Grid>
        </Info>
      </Details>
    </Content>
  )
}

export default Header
