import mirrorCreator from 'mirror-creator'

export const businessSegmentList = [
  {
    value: 'association',
    display: 'Association',
  },
  {
    value: 'customerEducationTraining',
    display: 'Customer Education/Training',
  },
  {
    value: 'enterprise',
    display: 'Enterprise',
  },
  {
    value: 'extendedEnterprise',
    display: 'Extended Enterprise',
  },
  {
    value: 'government',
    display: 'Government',
  },
  {
    value: 'higherEducation',
    display: 'Higher Education',
  },
  {
    value: 'largeEnterprise',
    display: 'Large Enterprise',
  },
  {
    value: 'midMarket',
    display: 'Mid-Market',
  },
  {
    value: 'nonProfit',
    display: 'Non-profit',
  },
  {
    value: 'smallBusiness',
    display: 'Small Business',
  },
]

export const actionTypes = mirrorCreator(
  [
    'OPEN_TRIAL_DIALOG',
    'CLOSE_TRIAL_DIALOG',

    'UPDATE_TRIAL_DATA',

    'CREATE_TRIAL_REQUESTED',
    'CREATE_TRIAL_SUCCEEDED',
    'CREATE_TRIAL_FAILED',
  ],
  { prefix: 'trials/' }
)
