import React from 'react'
import {
  styled,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import MuiIconButton from '@material-ui/core/IconButton'
import MuiCloseIcon from '@material-ui/icons/Close'
import TableHeader from 'contentPublisher/components/Compare/Table/Header'
import CompareTable from 'contentPublisher/components/Compare/Table'

const CloseButton = styled(MuiIconButton)(({theme}) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1000,
}))

const DialogTitle = styled(MuiDialogTitle)(({theme}) => ({
  paddingBottom: 0,
}))

const DialogContent = styled(MuiDialogContent)({
  paddingTop: 0,
})

const CompareDialog = ({onClose, ...props}) => {
  return (
    <MuiDialog
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      {...props}
    >
      <CloseButton onClick={onClose}>
        <MuiCloseIcon/>
      </CloseButton>
      <DialogTitle>
        <TableHeader/>
      </DialogTitle>
      <DialogContent>
        <CompareTable/>
      </DialogContent>
    </MuiDialog>
  )
}

export default CompareDialog
