import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))

const PurchasePlan = props => {
  const { purchasePlan } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateTrialData({ purchasePlan: e.target.value }))

  const error = !purchasePlan

  return (
    <Section
      title='When do you plan to purchase content?'
      error={error}
      {...props}
    >
      <RadioGroup value={purchasePlan || ''} onChange={handleChange}>
        <MuiFormControlLabel
          value='0-3 months'
          label='0-3 months'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='3-6 months'
          label='3-6 months'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='6-12 months'
          label='6-12 months'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='more than 1 year'
          label='more than a year'
          control={<MuiRadio />}
        />
      </RadioGroup>
    </Section>
  )
}

export default PurchasePlan
