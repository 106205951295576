import React, { useState } from 'react'
import {
  styled,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem
} from '@material-ui/core'
import MuiCheckIcon from '@material-ui/icons/Check'
import { useCurrentUser } from 'users/hooks'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

const Button = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))

const InsetMenuItem = styled(MuiMenuItem)({
  paddingLeft: 32,
  minWidth: 200,
  display: 'flex',
  justifyContent: 'space-between'
})

const SortBy = ({ handleSortTypeChange, sortType }) => {
  const currentUser = useCurrentUser()
  const [anchorEl, setAnchorEl] = useState(null)

  const isAdmin = currentUser && currentUser.role === 'admin'

  const openMenu = e => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const handleSortType = newSortType => {
				handleSortTypeChange(newSortType)
				closeMenu()
				
  }

  return (
    <>
      <Button color='primary' onClick={openMenu}>
        Sort By <ArrowDropDown />
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <InsetMenuItem onClick={() => handleSortType('A-Z')}>
          A-Z
          {sortType === 'A-Z' ? <MuiCheckIcon /> : null}
        </InsetMenuItem>
        <InsetMenuItem onClick={() => handleSortType('Z-A')}>
          Z-A
          {sortType === 'Z-A' ? <MuiCheckIcon /> : null}
        </InsetMenuItem>
        {isAdmin && (
          <InsetMenuItem onClick={() => handleSortType('updated-newest')}>
            Updated: Newest to Oldest
            {sortType === 'updated-newest' ? <MuiCheckIcon /> : null}
          </InsetMenuItem>
        )}
        {isAdmin && (
          <InsetMenuItem onClick={() => handleSortType('updated-oldest')}>
            Updated: Oldest to Newest
            {sortType === 'updated-oldest' ? <MuiCheckIcon /> : null}
          </InsetMenuItem>
        )}
      </MuiMenu>
    </>
  )
}

export default SortBy
