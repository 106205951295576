import React from 'react'
import { styled } from '@material-ui/core'
import { useCompareData } from './hooks'
import { Section, Subsection } from './Sections'
import Row from './Row'
import Values from './Values'
// import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')(({ theme }) => ({
  fontSize: 12,
  margin: 0,
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const ListItem = styled('li')(({ theme }) => ({
  whiteSpace: 'pre-line',
  marginBottom: theme.spacing(0.5)
}))

const CourseStandards = props => {
  const { systems, courseStandards } = useCompareData()
  // const checkIfVisible = feature =>
  //   feature && !HIDDEN_FEATURES.includes(feature.name)
  // const checkIfChildFeatureIsVisible = childFeature => !HIDDEN_FEATURES.includes(childFeature)

  return (
    <>
      <Section>Course Standards</Section>
      {courseStandards.allIds.map(id => {
        const courseStandard = courseStandards.byId[id]
        // const isFeatureVisible = checkIfVisible(feature)
        const { children } = courseStandard
        // if (isFeatureVisible) {
        let rowContent
        if (children) {
          const sortedChildrenCourseStandard = children
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
          rowContent = systems.map(sys => {
            const childCourseStandards = sortedChildrenCourseStandard
              .filter(c => sys?.features.includes(c.id))
              .map(c => c.name)

            if (childCourseStandards.length > 0) {
              return (
                <List key={sys.id}>
                  {childCourseStandards.map((v, i) => {
                    // const isChildVisible = checkIfChildFeatureIsVisible(v)
                    // return isChildVisible && <ListItem key={i}>{v}</ListItem>
                    return <ListItem key={i}>{v}</ListItem>
                  })}
                </List>
              )
            } else {
              return <Values key={sys.id}>None</Values>
            }
          })
        } else {
          rowContent = systems.map(s => (
            <Values key={s.id}>
              {s?.courseStandards.includes(id) ? 'Yes' : 'No'}
            </Values>
          ))
        }

        return (
          <React.Fragment key={id}>
            <Subsection>{courseStandard.name}</Subsection>
            <Row>{rowContent}</Row>
          </React.Fragment>
        )
        // } else {
        //   return null
        // }
      })}
    </>
  )
}

export default CourseStandards
