import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core'
import { useLabelWidth } from 'ui/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCurrentUser } from 'users/hooks'
import { useCookies } from 'react-cookie'
import { THIRTY_DAYS } from 'constants/timeIntervals'
import { useDurationState } from 'contentPublisher/hooks'
import MuiCheckbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Fieldset = styled(({ theme, error, ...props }) => (
  <fieldset {...props} />
))(({ theme, error }) => ({
  margin: 0,
  borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled(({ theme, error, ...props }) => <legend {...props} />)(
  ({ theme, error }) => ({
    padding: 5,
    fontSize: 12,
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  })
)

const DurationCheckbox = styled(MuiCheckbox)({})

const DurationsContainer = styled('div')({})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const Durations = props => {
  const filters = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [labelWidth, labelRef] = useLabelWidth()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const { data } = useDurationState()

  // const durations = Object.values(data.byId).sort(nameSorter)
  const durations = Object.values(data.byId)
  const currentDurations = filters.durations

  const handleChange = event => {
    let duration = event.target.value
    let updatedDurations = [duration, ...filters.durations]
    const isSelected = currentDurations.includes(duration)
    if (isSelected)
      updatedDurations = updatedDurations.filter(
        selectedDuration => selectedDuration !== duration
      )
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({ durations: updatedDurations }))
  }

  return (
    <Fieldset {...props} >
      <Legend>Durations</Legend>
      <DurationsContainer>
        {durations.map(duration => (
          <FormControlLabel
            key={duration.id}
            style={{ marginLeft: 0, display: 'block' }}
            control={
              <DurationCheckbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                checked={currentDurations.includes(duration.id)}
                onChange={handleChange}
                name={duration.name}
                color='primary'
                value={duration.id}
              />
            }
            label={<span style={{ fontSize: '14px' }}>{duration.name}</span>}
          />
        ))}
      </DurationsContainer>
    </Fieldset>
  )
}

export default Durations
