import React from 'react'
import {Switch, Route} from 'react-router-dom'
import UserRoute from './UserRoute'
import Login from 'users/components/Login'
import Logout from 'users/components/Logout'
import Registration from 'users/components/Registration'
import Password from 'users/components/Password'
import Invitation from 'users/components/Invitation'
// import Onboarding from 'searches/components/Onboarding'
import Dashboard from 'app/components/Dashboard'

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login}/>
      <Route path="/logout" component={Logout} />
      <Route path="/register" component={Registration}/>
      <Route path="/password" component={Password}/>
      <Route path="/invitation" component={Invitation}/>

      {/* <UserRoute path="/welcome" component={Onboarding}/> */}
      <UserRoute component={Dashboard} redirect="/register"/>
    </Switch>
  )
}

export default Routes
