import React from 'react'
import {
  styled,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput as MuiOutlinedInput,
  withStyles
} from '@material-ui/core'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Rating from '@material-ui/lab/Rating'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { RATINGS_DISPLAY_VALUES } from './constants'

const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.primary.main
  },
  iconHover: {
    color: theme.palette.primary.main
  },
  sizeSmall: {
    fontSize: '1.75em'
  },
  sizeLarge: {
    fontSize: '2.25em'
  }
}))(Rating)

const Content = styled('div')({})
const RatingContainer = styled('div')({
  flexBasis: '50%'
})
const ContentContainer = styled('div')({
  display: 'flex'
})

const RatingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '.5rem 0',
  alignItems: 'center'
})
const RatingValue = styled('p')({
  margin: 0,
  marginLeft: '.25rem',
  color: 'rgba(0, 0, 0, 0.54)'
})

const RatingValueWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const RatingFeatureTitle = styled('p')({
  fontWeight: 'bold'
})

const OverallRatingContainer = styled('div')({
  textAlign: 'center',
  flexBasis: '50%'
})

const OverallRatingValueContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

const OverallRatingTitle = styled('p')({
  fontWeight: 'bold'
})
const OverallRatingValue = styled('p')({
  margin: '0'
})

const InputLabel = styled(MuiInputLabel)({
  whiteSpace: 'nowrap',
  color: 'rgba(0, 0, 0, 0.87)',
  position: 'relative',
  top: '2px'
})

const defaultRatings = {
  learnerExperience: null,
  administrationUX: null,
  nexGenFeatures: null,
  systemUI: null,
  metricsAndReporting: null,
  servicesAndSupport: null,
  enterpriseStrength: null,
  customerEducationStrength: null
}

const Ranking = ({ rankings }) => {
  const ratingsValues = rankings || defaultRatings
  const { average: averageRankingFromdB } = ratingsValues

  const ratingsToRender = { ...ratingsValues }

  if (ratingsToRender.average) {
    delete ratingsToRender.average
  }

  const roundedAverageRating = averageRankingFromdB
    ? Math.floor(averageRankingFromdB * 2) / 2
    : 0

  const sortedRatings = Object.keys(ratingsToRender)
    .sort()
    .reduce((accumulator, currentValue) => {
      accumulator[currentValue] = ratingsToRender[currentValue]
      return accumulator
    }, {})

  return (
    <Content>
      <SectionTitle>Craig's Ratings</SectionTitle>
      <ContentContainer>
        <RatingContainer>
          {Object.keys(sortedRatings).map(ratingType => {
            const value = ratingsValues[ratingType]
            const roundedRating = Math.floor(value * 2) / 2
            const displayName = RATINGS_DISPLAY_VALUES[ratingType]
            return (
              <RatingWrapper key={ratingType}>
                <InputLabel htmlFor={ratingType}>{displayName}</InputLabel>
                <RatingValueWrapper>
                  <StyledRating
                    name={ratingType}
                    value={roundedRating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize='inherit' />}
                    readOnly
                    size='small'
                  />
                  <RatingValue>{value ? value.toFixed(1) : '0.0'}</RatingValue>
                </RatingValueWrapper>
              </RatingWrapper>
            )
          })}
        </RatingContainer>
        <OverallRatingContainer>
          <OverallRatingTitle>Overall Rating</OverallRatingTitle>
          <OverallRatingValueContainer>
            <StyledRating
              name='averageRating'
              value={roundedAverageRating}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize='inherit' />}
              readOnly
              size='large'
            />
            <OverallRatingValue>
              {(roundedAverageRating || 0).toFixed(1)} out of 5
            </OverallRatingValue>
          </OverallRatingValueContainer>
        </OverallRatingContainer>
      </ContentContainer>
    </Content>
  )
}

export default Ranking
