import React from 'react'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { styled } from '@material-ui/core'
import { Layout, Body, Info, Main } from 'ui/components/DividedLayout'
import SetPasswordForm from 'users/components/Registration/SetPassword/Form'
import { usePasswordState } from 'users/hooks'
import Accept from './Accept'

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 6,
})

const Subtitle = styled('h4')({
  fontSize: 16,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 18,
})

const Invitation = props => {
  const { email } = queryString.parse(props.location.search)
  const { status } = usePasswordState()

  if (status === 'completed') return <Redirect to='/' />

  let subtitle = 'Click the button below to complete your registration'
  let content = <Accept email={email} />

  if (status) {
    subtitle = 'Check your email for a code to set your password'
    content = (
      <SetPasswordForm {...props} email={email} buttonTitle='Set Password' />
    )
  }

  return (
    <Layout>
      <Body>
        <Info />
        <Main>
          <Title>Welcome to FindContent</Title>
          <Subtitle>{subtitle}</Subtitle>
          {content}
        </Main>
      </Body>
    </Layout>
  )
}

export default Invitation
