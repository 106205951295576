/* eslint-disable react/jsx-no-target-blank */

import React from 'react'
import { styled } from '@material-ui/core/styles'

const Content = styled('p')({
  fontSize: 12,
  margin: 0,
})

const termsUrl = 'https://www.findcontent.io/terms-of-service-agreement'
const privacyUrl = 'https://www.findcontent.io/privacy-policy'

const Terms = ({ children }) => (
  <a href={termsUrl} target='_blank'>
    {children}
  </a>
)
const Privacy = ({ children }) => (
  <a href={privacyUrl} target='_blank'>
    {children}
  </a>
)

const Legal = props => {
  return (
    <Content {...props}>
      By signing up, you agree to our <Terms>terms of service</Terms> and{' '}
      <Privacy>privacy policy</Privacy>.
    </Content>
  )
}

export default Legal
