import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@material-ui/core'

import { getShowDialog, getShowErrors } from 'trials/store/selectors'
import { closeTrialDialog, createTrial } from 'trials/store/actions'
import ContactCard from './ContactCard'
import CurrentSystem from './CurrentSystem'
import NumberOfUsers from './NumberOfUsers'
import PurchasePlan from './PurchasePlan'
import ConsentCheckbox from './ConsentCheckbox'

import OrganizationType from './OrganizationType'
import AudienceTypes from './AudienceTypes'
import Status from './Status'
import Timeframe from './NumberOfUsers'
import DeploymentType from './DeploymentType'
import Migration from './Migration'

const Title = styled('h2')({
  margin: 0,
})

const Error = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.error.main,
  fontSize: 16,
  fontWeight: 500,
}))

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

const TrialsForm = styled('form')({})

const TrialDialog = props => {
  const showDialog = useSelector(getShowDialog)
  const showErrors = useSelector(getShowErrors)
  const dispatchRedux = useDispatch()

  const handleClose = () => dispatchRedux(closeTrialDialog())
  const handleSubmit = e => {
    e.preventDefault()
    dispatchRedux(createTrial())
  }

  let errorMessage
  if (showErrors) {
    errorMessage = (
      <Error>Please complete all the fields highlighted below:</Error>
    )
  }

  return (
    <MuiDialog open={showDialog} onClose={handleClose} {...props}>
      <MuiDialogTitle disableTypography>
        <Title>Request Trial</Title>
        {errorMessage}
      </MuiDialogTitle>
      <TrialsForm onSubmit={handleSubmit}>
        <MuiDialogContent>
          <ContactCard />
          <CurrentSystem />
          <NumberOfUsers />
          <PurchasePlan />
          <ConsentCheckbox />
        </MuiDialogContent>
        <DialogActions>
          <MuiButton color='primary' onClick={handleClose}>
            Cancel
          </MuiButton>
          <MuiButton variant='contained' color='primary' type='submit'>
            Submit Request
          </MuiButton>
        </DialogActions>
      </TrialsForm>
    </MuiDialog>
  )
}

export default TrialDialog
