import React from 'react'
import {
  styled,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'contentPublisher/hooks'
import Thumbnail from './Thumbnail'

const Content = styled('div')({
  display: 'grid',
  gridTemplateColumns: '250px auto',
  gridColumnGap: 20,
})

const StyledThumbnail = styled(Thumbnail)({
  gridRow: '1/5',
})

const BasicInfo = (props) => {
  const [application, updateApplication] = useEditApplication()

  return (
    <Content {...props}>
      <StyledThumbnail/>

      <MuiTextField
        defaultValue={application.name}
        label="Content Company Name"
        variant="outlined"
        margin="dense"
        onChange={(e) => updateApplication({name: e.target.value})}
      />
      {/* <MuiTextField
        defaultValue={application.company}
        label="Company Name"
        variant="outlined"
        margin="dense"
        onChange={(e) => updateApplication({company: e.target.value})}
      /> */}
      <MuiTextField
        defaultValue={application.country}
        label="Headquarters"
        variant="outlined"
        margin="dense"
        onChange={(e) => updateApplication({country: e.target.value})}
      />
      <MuiTextField
        defaultValue={application.website}
        label="Website"
        variant="outlined"
        margin="dense"
        onChange={(e) => updateApplication({website: e.target.value})}
      />
    </Content>
  )
}

export default BasicInfo
