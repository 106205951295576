import { green } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

// Based on https://coolors.co/009ade-0267c1-efa00b-d65108-591f0a
//
// #009ADE - Rich Electric Blue
// #0267C1 - French Blue
// #EFA00B - Gamboge
// #D65108 - Burnt Orange
// #591F0A - Seal Brown

const theme = createTheme({
  palette: {
    primary: {
      main: '#009ade',
    },
    secondary: {
      main: '#0267c1',
    },
    background: {
      default: '#009ade',
    },
    success: {
      main: green[600],
    }
  },
})

export default theme
