import produce from 'immer'
import { actionTypes } from './constants'

const initialState = {
  currentUser: null,
  authentication: {
    status: 'unauthenticated'
  },
  registration: {
    status: undefined
  },
  password: {
    status: undefined
  },
  users: {
    status: null,
    data: {
      total: undefined,
      byId: {},
      allIds: []
    }
  },
  guestUser: {
    hasCookieConsent: false
  }
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUESTED:
      draft.authentication.status = 'authenticating'
      break

    case actionTypes.LOGIN_SUCCEEDED:
      draft.authentication.status = 'authenticated'
      delete draft.registration.error
      draft.currentUser = action.payload.auth.user
      break

    case actionTypes.LOGIN_FAILED:
      draft.authentication.status = 'failed'
      break

    case actionTypes.LOGOUT_COMPLETED:
      return initialState

    case actionTypes.CREATE_USER_REQUESTED:
      if (draft.authentication.status !== 'authenticated') {
        // new user via registration
        // draft.registration.status = 'started'
      } else {
        // new user via admin
      }
      break

    case actionTypes.CREATE_USER_SUCCEEDED:
      if (draft.authentication.status !== 'authenticated') {
        // new user via registration
        draft.registration.status = 'succeeded'
        delete draft.registration.errors
        draft.currentUser = action.payload.user
      } else {
        // new user via admin
        const { user } = action.payload
        const { _id: id } = user
        const userIds = new Set(draft.users.data.allIds)

        // add user to redux
        draft.users.data.byId[id] = { id, ...user }
        userIds.add(id)
        draft.users.data.allIds = Array.from(userIds)

        // update user count
        draft.users.data.total = userIds.size
      }
      break

    case actionTypes.CREATE_USER_FAILED:
      if (draft.authentication.status !== 'authenticated') {
        // new user via registration
        draft.registration.errors = action.payload.error.errors

        switch (action.payload.error.code) {
          case 409:
            draft.registration.status = 'conflict'
            break

          default:
            draft.registration.status = 'failed'
        }
      } else {
        // new user via admin
      }
      break

    case actionTypes.SEND_PASSWORD_RESET:
      if (!draft.password.status) draft.password.status = 'requested'
      break

    case actionTypes.UPDATE_PASSWORD_REQUESTED:
      draft.password.status = 'started'
      break

    case actionTypes.UPDATE_PASSWORD_SUCCEEDED:
      draft.password.status = 'completed'
      break

    case actionTypes.UPDATE_PASSWORD_FAILED:
      draft.password.status = 'failed'
      break

    case actionTypes.UPDATE_CURRENT_USER_SUCCEEDED:
      draft.currentUser = action.payload
      break

    case actionTypes.FETCH_USERS_REQUESTED:
      draft.users.status = 'fetching'
      break

    case actionTypes.FETCH_USERS_SUCCEEDED: {
      const users = action.payload.results.data
      const userIds = new Set(draft.users.data.allIds)

      users.forEach(user => {
        userIds.add(user.id)
        draft.users.data.byId[user.id] = user
      })

      draft.users.data.allIds = Array.from(userIds)
      draft.users.data.total = action.payload.results.total
      if (action.payload.completed) draft.users.status = 'succeeded'

      break
    }

    case actionTypes.FETCH_USERS_FAILED:
      break

    case actionTypes.PATCH_USER_REQUESTED:
      break

    case actionTypes.PATCH_USER_SUCCEEDED: {
      const { user } = action.payload

      // update user in redux
      draft.users.data.byId[user.id] = user

      break
    }

    case actionTypes.PATCH_USER_FAILED:
      break

    case actionTypes.REMOVE_USER_REQUESTED:
      break

    case actionTypes.REMOVE_USER_SUCCEEDED: {
      const { _id: id } = action.payload.user
      const userIds = new Set(draft.users.data.allIds)

      // remove user from redux
      delete draft.users.data.byId[id]
      userIds.delete(id)
      draft.users.data.allIds = Array.from(userIds)

      // update user count
      draft.users.data.total = userIds.size

      break
    }

    case actionTypes.UPDATE_GUEST_COOKIE_CONSENT:
      break
    case actionTypes.REMOVE_USER_FAILED:
      break
    case actionTypes.RESET_USER_STATE:
      return initialState
      break

    // no default
  }
}, initialState)

export default reducer
