import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup
} from '@material-ui/core'
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { getReportOptions } from 'reports/store/selectors'
import { setReportOptions } from 'reports/store/actions'

const Content = styled('div')({})

const DateRange = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(4),
  display: 'grid',
  gridColumnGap: theme.spacing(2),
  gridAutoFlow: 'column'
}))

const popoverProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'center'
  }
}

const Range = props => {
  const { range, startDate, endDate } = useSelector(getReportOptions)
  const dispatchRedux = useDispatch()

  const disableDates = range !== 'dates'

  const setOptions = options => dispatchRedux(setReportOptions(options))

  return (
    <Content {...props}>
      <MuiRadioGroup
        value={range}
        onChange={e => setOptions({ range: e.target.value })}
      >
        <MuiFormControlLabel label='All' value='all' control={<MuiRadio />} />
        <MuiFormControlLabel
          label='Date Range'
          value='dates'
          control={<MuiRadio />}
        />
      </MuiRadioGroup>
      <DateRange>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiKeyboardDatePicker
            label='Start date'
            format='MM/DD/YYYY'
            variant='inline'
            disabled={disableDates}
            value={startDate}
            onChange={date => setOptions({ startDate: date })}
            maxDate={endDate}
            autoOk
            disableFuture
            disableToolbar
            PopoverProps={popoverProps}
          />
          <MuiKeyboardDatePicker
            label='End date'
            format='MM/DD/YYYY'
            variant='inline'
            disabled={disableDates}
            value={endDate}
            onChange={date => setOptions({ endDate: date })}
            minDate={startDate}
            autoOk
            disableFuture
            disableToolbar
            PopoverProps={popoverProps}
          />
        </MuiPickersUtilsProvider>
      </DateRange>
    </Content>
  )
}

export default Range
