import React from 'react'
import { styled, Chip as MuiChip } from '@material-ui/core'
// import MuiAddIcon from '@material-ui/icons/Add'
import { useApplicationState } from 'contentPublisher/hooks'

const Fieldset = styled('fieldset')(({ theme }) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled('legend')(({ theme }) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary,
}))

const Chips = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const AppChip = styled(MuiChip)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

// const AddChip = styled(MuiChip)({
// })

const Applications = ({ ids = [], role, ...props }) => {
  const { data } = useApplicationState()

  if (ids.length === 0) return null

  let appChips = ids.map(id => {
    const { name } = data.byId[id]

    return (
      <AppChip
        key={id}
        label={name}
        size='small'
        // onDelete={() => {}}
      />
    )
  })

  const title = role === 'user' ? 'Saved Publishers' : 'Admin Vendor Systems'

  return (
    <Fieldset {...props}>
      <Legend>{title}</Legend>
      <Chips>
        {appChips}
        {/* <AddChip
          label="Add"
          icon={<MuiAddIcon/>}
          color="primary"
          size="small"
          onClick={() => {}}
        /> */}
      </Chips>
    </Fieldset>
  )
}

export default Applications
