import debounce from 'lodash/debounce'
import { useSelector, useDispatch } from 'react-redux'
import {
  editApplicationChange,
  addToUploadQueue,
} from 'contentPublisher/store/actions'
import {
  getApplicationsState,
  getAudienceTypesState,
  getCourseStandardsState,
  getIndustriesState,
  getFeaturesState,
  getEditApplicationState,
  getContentTypeState,
  getDurationState,
  getSubjectTypeState,
  getDeliveryTypesState,
  getLanguageState,
  getPublisherState,
  getCourseStandardState
} from 'contentPublisher/store/selectors'

export const useAppReadyState = () => {
  const { status: applicationState } = useApplicationState()
  const { status: featuresState } = useFeaturesState()

  return applicationState === 'succeeded' && featuresState === 'succeeded'
}

export const useApplicationState = () => {
  const state = useSelector(getApplicationsState)

  return state
}

export const useContentTypeState = () => {
  const state = useSelector(getContentTypeState)

  return state
}

export const useAudienceTypesState = () => {
  const state = useSelector(getAudienceTypesState)

  return state
}

export const useCourseStandardsState = () => {
  const state = useSelector(getCourseStandardsState)

  return state
}

export const useIndustriesState = () => {
  const state = useSelector(getIndustriesState)

  return state
}

export const useFeaturesState = () => {
  const state = useSelector(getFeaturesState)

  return state
}

export const useDurationState = () => {
  const state = useSelector(getDurationState)

  return state
}

export const useSubjectTypesState = () => {
  const state = useSelector(getSubjectTypeState)

  return state
}

export const useDeliveryTypesState = () => {
  const state = useSelector(getDeliveryTypesState)

  return state
}

export const useCourseStandardState = () => {
  const state = useSelector(getCourseStandardState)

  return state
}

export const useLangaugeState = () => {
  const state = useSelector(getLanguageState)

  return state
}

export const usePublisherState = () => {
  const state = useSelector(getPublisherState)

  return state
}

export const useEditApplication = () => {
  const { data } = useSelector(getEditApplicationState)
  const dispatchRedux = useDispatch()

  const update = debounce(
    change => dispatchRedux(editApplicationChange(change)),
    250
  )

  return [data, update]
}

export const useUploadFile = () => {
  const dispatchRedux = useDispatch()

  const uploadFile = upload => dispatchRedux(addToUploadQueue(upload))

  return uploadFile
}

export const useSystemData = id => {
  const { data } = useSelector(getApplicationsState)

  return data.byId[id]
}
