export const getEditApplicationState = store => store.contentPublisher.editing
export const getCompareSystemIds = store =>
  store.contentPublisher.compare.systemIds
export const getApplicationsState = store => store.contentPublisher.applications
export const getContentTypeState = store => store.contentPublisher.contentTypes
export const getFeaturesState = store => store.contentPublisher.features
export const getDurationState = store => store.contentPublisher.durations
export const getSubjectTypeState = store => store.contentPublisher.subjectTypes
export const getDeliveryTypesState = store =>
  store.contentPublisher.deliveryTypes
export const getCourseStandardState = store => store.contentPublisher.courseStandards
export const getLanguageState = store => store.contentPublisher.languages
export const getPublisherState = store => store.contentPublisher.publishers

export const getAudienceTypesState = store =>
  store.contentPublisher.audienceTypes
export const getCourseStandardsState = store =>
  store.contentPublisher.courseStandards
export const getIndustriesState = store => store.contentPublisher.industries
