import React, {useState} from 'react'
import {
  styled,
  Avatar as MuiAvatar,
  Chip as MuiChip,
  Tooltip as MuiTooltip,
} from '@material-ui/core'
import UserIcon from '@material-ui/icons/Person'
import AddIcon from '@material-ui/icons/Add'
import AddDialog from './AddDialog'
import {useEditApplication} from 'contentPublisher/hooks'
import {useUsers} from 'users/hooks'

const Fieldset = styled('fieldset')(({theme}) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled('legend')(({theme}) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary,
}))

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const Chip = styled(MuiChip)(({theme}) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const Admins = (props) => {
  const [application, updateApplication] = useEditApplication()
  const [showDialog, setShowDialog] = useState(false)
  const {data} = useUsers()

  const appAdmins = application.admins || []

  const {admins: ids = []} = application
  const admins = []

  const removeAdmin = (id) => {
    const set = new Set(appAdmins)
    set.delete(id)
    updateApplication({admins: Array.from(set)})
  }

  ids.forEach((id) => {
    const admin = data.byId[id]

    if (!admin) return

    const name = `${admin.firstName} ${admin.lastName}`
    const avatar = admin.avatar ? <MuiAvatar src={admin.avatar.uri}/> : <MuiAvatar><UserIcon/></MuiAvatar>

    admins.push(
      <MuiTooltip key={id} title={admin.email}>
        <Chip
          label={name}
          avatar={avatar}
          onDelete={() => removeAdmin(id)}
          variant="outlined"
        />
      </MuiTooltip>
    )
  })

  return (
    <Fieldset {...props}>
      <Legend>Vendor Admins</Legend>
      <Grid>
        {admins}
        <Chip
          label="Add Admins"
          color="primary"
          icon={<AddIcon/>}
          onClick={() => setShowDialog(true)}
        />
      </Grid>
      <AddDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </Fieldset>
  )
}

export default Admins
