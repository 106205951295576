import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core'
import { websiteLinkVisited } from 'contentPublisher/store/actions'

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.primary.dark,
  display: 'block',
  textDecoration: 'none',
}))

const WebsiteLink = ({ contentPublisher, ...props }) => {
  const dispatchRedux = useDispatch()

  if (!contentPublisher.website) return null

  let websiteUrl = contentPublisher.website

  if(!contentPublisher.website.includes("https://") && !contentPublisher.website.includes("http://")) {
    websiteUrl = 'http://' + websiteUrl
  }


  const handleClick = () =>
    dispatchRedux(websiteLinkVisited(contentPublisher.id))

  return (
    <Link
      href={websiteUrl}
      target='_blank'
      rel='noopener'
      onClick={handleClick}
      {...props}
    >
      {contentPublisher.website}
    </Link>
  )
}

export default WebsiteLink
