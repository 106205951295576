import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useDeliveryTypesState } from 'contentPublisher/hooks'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')({})

const Value = styled('li')({})

const Delivery = ({ delivery = [] }) => {
  const { data } = useDeliveryTypesState()

  if (delivery.length === 0) return null
  const checkForDeliveryTypesVisibility = cs => !HIDDEN_FEATURES.includes(cs.name)
  const deliveryToRender = delivery.filter(id => {
    const deliveryFromId = data.byId[id]
    const isVisible =
    deliveryFromId &&
    checkForDeliveryTypesVisibility(deliveryFromId)
    return Boolean(deliveryFromId) && isVisible
  })
  return (
    <>
      <SectionTitle>Delivery Types</SectionTitle>
      <List>
        {deliveryToRender.map(id => (
          <Value key={id}>{data.byId[id].name}</Value>
        ))}
      </List>
    </>
  )
}

export default Delivery
