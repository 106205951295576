import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {useEditApplication} from 'contentPublisher/hooks'

const PremiumLabel =  styled(MuiFormControlLabel)({
  marginTop: 8,
  marginRight: 24,
})

const Premium = (props) => {
  const [application, updateApplication] = useEditApplication()

  const updatePremium = e => updateApplication({isPremium: e.target.checked})

  const premiumCheck = <MuiCheckbox color="primary" checked={!!application.isPremium} onChange={updatePremium}/>

  return (
    <PremiumLabel
      label="Premium Vendor"
      control={premiumCheck}
      labelPlacement="end"
    />
  )
}

export default Premium
