import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useSubjectTypesState } from 'contentPublisher/hooks'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')({})

const Value = styled('li')({})

const Category = ({ category = [] }) => {
  const { data } = useSubjectTypesState()

  if (category.length === 0) return null
  const checkForCategoryVisibility = cs => !HIDDEN_FEATURES.includes(cs.name)
  const categoryToRender = category.filter(id => {
    const categoryFromId = data.byId[id]
    const isVisible =
    categoryFromId &&
    checkForCategoryVisibility(categoryFromId)
    return Boolean(categoryFromId) && isVisible
  })
  return (
    <>
      <SectionTitle>Category</SectionTitle>
      <List>
        {categoryToRender.map(id => (
          <Value key={id}>{data.byId[id].name}</Value>
        ))}
      </List>
    </>
  )
}

export default Category
