import { LOCATION_CHANGE } from 'connected-react-router'
import { all, select, takeEvery } from 'redux-saga/effects'
import { getCurrentUser } from 'users/store/selectors'
import { actionTypes as userActionTypes } from 'users/store/constants'
import { actionTypes as contentPublisherActionTypes } from 'contentPublisher/store/constants'
import { actionTypes as trialActionTypes } from 'trials/store/constants'
import { getApplicationsState } from 'contentPublisher/store/selectors'
import { getTrialData } from 'trials/store/selectors'

const dataLayer = window.dataLayer

function* analyticsSaga(action) {
  const state = yield select()
  const currentUser = getCurrentUser(state)
  const { data: appData } = getApplicationsState(state)
  const trialData = getTrialData(state)

  const data = {
    event: action.type,
  }

  if (currentUser) {
    data.user = {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
    }
  }

  switch (action.type) {
    case userActionTypes.LOGIN_REQUESTED:
      data.email = action.payload.credentials.email
      break

    case userActionTypes.CREATE_USER_REQUESTED:
      data.userData = action.payload.user
      break

    case userActionTypes.SEND_PASSWORD_RESET:
      data.email = action.payload.value.email
      break

    case userActionTypes.ADD_LMS_TO_LIST:
      data.system = action.payload.map(id => appData.byId[id].name)
      break

    case userActionTypes.REMOVE_LMS_FROM_LIST:
      data.system = appData.byId[action.payload].name
      break

    case contentPublisherActionTypes.VIEW_CONTENT_PROVIDER_DETAILS:
    case contentPublisherActionTypes.VIEW_APPLICATION_CONTACT_INFO:
      data.system = appData.byId[action.payload.id].name
      break

    case trialActionTypes.OPEN_TRIAL_DIALOG:
      data.system = appData.byId[action.payload.systemId].name
      break

    case trialActionTypes.CREATE_TRIAL_REQUESTED:
      data.trialData = trialData
      break

    case userActionTypes.LOGIN_SUCCEEDED:
    case userActionTypes.LOGIN_FAILED:
    case userActionTypes.LOGOUT_REQUESTED:
    case userActionTypes.CREATE_USER_SUCCEEDED:
    case userActionTypes.CREATE_USER_FAILED:
    case userActionTypes.UPDATE_PASSWORD_REQUESTED:
    case userActionTypes.UPDATE_PASSWORD_SUCCEEDED:
    case userActionTypes.UPDATE_PASSWORD_FAILED:
      break

    default:
      // data.payload = action.payload
      break
  }

  // yield console.log({data})

  yield dataLayer.push(data)
}

function* pageViewSaga(action) {
  const { pathname } = action.payload.location

  const data = {
    event: 'location_change',
    pathname,
  }

  // yield console.log({data})

  yield dataLayer.push(data)
}

function* watchAnalytics() {
  const analyticsActions = [
    userActionTypes.LOGIN_REQUESTED,
    //userActionTypes.LOGIN_SUCCEEDED,
    userActionTypes.LOGIN_FAILED,
    //userActionTypes.LOGOUT_REQUESTED,
    userActionTypes.CREATE_USER_REQUESTED,
    userActionTypes.CREATE_USER_SUCCEEDED,
    userActionTypes.CREATE_USER_FAILED,
    userActionTypes.SEND_PASSWORD_RESET,
    userActionTypes.UPDATE_PASSWORD_REQUESTED,
    userActionTypes.UPDATE_PASSWORD_SUCCEEDED,
    userActionTypes.UPDATE_PASSWORD_FAILED,
    userActionTypes.ADD_LMS_TO_LIST,
    userActionTypes.REMOVE_LMS_FROM_LIST,
    contentPublisherActionTypes.VIEW_CONTENT_PROVIDER_DETAILS,
    contentPublisherActionTypes.VIEW_APPLICATION_CONTACT_INFO,
    trialActionTypes.OPEN_TRIAL_DIALOG,
    trialActionTypes.CREATE_TRIAL_REQUESTED,
  ]

  yield takeEvery(analyticsActions, analyticsSaga)
}

function* watchPageViews() {
  const pageViewActions = [LOCATION_CHANGE]

  yield takeEvery(pageViewActions, pageViewSaga)
}

function* rootSaga() {
  yield all([
    //watchAnalytics(), 
    watchPageViews(),
  ])
}

export default rootSaga
