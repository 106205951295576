import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Paper as MuiPaper,
  TextField as MuiTextField,
} from '@material-ui/core'
import { useCurrentUser } from 'users/hooks'
import { profileUserSchema } from 'users/store/validations'
import produce from 'immer'
import { updateCurrentUser } from 'users/store/actions'
import CountrySelect from 'ui/components/CountrySelect'

const Content = styled(MuiPaper)({
  marginTop: 20,
  padding: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

const Title = styled('h2')({
  margin: 0,
  marginBottom: 20,
})

const Form = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 8px 1fr',
  maxWidth: 800,

  // default text fields
  '& > *': {
    gridColumn: '1 / 4',
  },

  [theme.breakpoints.up('md')]: {
    // first name field
    '& > :nth-child(2)': {
      gridColumn: '1',
    },

    // last name field
    '& > :nth-child(3)': {
      gridColumn: '3',
    },

    // city field
    '& > :nth-child(8)': {
      gridColumn: '1',
    },

    // state field
    '& > :nth-child(9)': {
      gridColumn: '3',
    },
  },
}))

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center',
})

const UpdateButton = styled(MuiButton)({})

const Profile = props => {
  const currentUser = useCurrentUser()
  const [user, setUser] = useState(currentUser)
  const dispatchRedux = useDispatch()

  let errors = {}
  try {
    profileUserSchema.validateSync(user, { abortEarly: false })
  } catch ({ inner }) {
    inner.forEach(e => (errors[e.path] = e.message))
  }

  const updateUser = changes => {
    const updatedUser = produce(user, draft => Object.assign(draft, changes))
    setUser(updatedUser)
  }

  const handleSubmit = () => {
    const updatedUserPayload = { ...user }
    // no need to include the trials data when updating the profile
    delete updatedUserPayload['trials']
    dispatchRedux(updateCurrentUser(updatedUserPayload))
  }

  return (
    <Content>
      <Form onSubmit={handleSubmit}>
        <Title>My Profile</Title>
        <MuiTextField
          label='First name'
          type='text'
          value={user.firstName}
          onChange={e => updateUser({ firstName: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='given-name'
          error={!!errors.firstName}
          helperText={errors.firstName}
          autoFocus
        />
        <MuiTextField
          label='Last name'
          type='text'
          value={user.lastName}
          onChange={e => updateUser({ lastName: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='family-name'
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <MuiTextField
          label='Email'
          type='email'
          value={user.email}
          onChange={e => updateUser({ email: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='email'
          error={!!errors.email}
          helperText={errors.email}
        />
        <MuiTextField
          label='Phone'
          type='tel'
          value={user.phone}
          onChange={e => updateUser({ phone: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='tel'
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <MuiTextField
          label='Company name'
          type='text'
          value={user.companyName}
          onChange={e => updateUser({ companyName: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='organization'
          error={!!errors.companyName}
          helperText={errors.companyName}
        />
        <MuiTextField
          label='Job title'
          type='text'
          value={user.jobTitle}
          onChange={e => updateUser({ jobTitle: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='organization-title'
          error={!!errors.jobTitle}
          helperText={errors.jobTitle}
        />
        <MuiTextField
          label='City'
          type='text'
          value={user.city}
          onChange={e => updateUser({ city: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='address-level2'
          error={!!errors.city}
          helperText={errors.city}
        />
        <MuiTextField
          label='State/Province'
          type='text'
          value={user.state}
          onChange={e => updateUser({ state: e.target.value })}
          variant='outlined'
          margin='dense'
          fullWidth
          autoComplete='address-level1'
          error={!!errors.state}
          helperText={errors.state}
        />
        <CountrySelect
          value={user.country}
          onChange={e => updateUser({ country: e.target.value })}
          error={!!errors.country}
        />
        <Actions>
          <UpdateButton
            color='primary'
            variant='contained'
            disabled={Object.keys(errors).length > 0}
            onClick={handleSubmit}
          >
            Update
          </UpdateButton>
        </Actions>
      </Form>
    </Content>
  )
}

export default Profile
