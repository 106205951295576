import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import getAppModule from 'app/store/module'
import getLmsModule from 'contentPublisher/store/module'
import getSearchesModule from 'searches/store/module'
import getUsersModule from 'users/store/module'
import getLeadsModule from 'leads/store/module'
import getAnalyticsModule from 'analytics/store/module'
import getTrialsModule from 'trials/store/module'

const store = createStore(
  {
    initialState: {},
    enhancers: [],
    extensions: [getSagaExtension()],
  },

  // dynamic modules
  getAppModule(),
  getLmsModule(),
  getSearchesModule(),
  getUsersModule(),
  getLeadsModule(),
  getAnalyticsModule(),
  getTrialsModule()
)

export default store
