import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import { useEditApplication } from 'contentPublisher/hooks'

const PublishedLabel = styled(MuiFormControlLabel)({
  marginTop: 8,
  marginRight: 24,
})

const Published = props => {
  const [application, updateApplication] = useEditApplication()

  const updatePublished = e =>
    updateApplication({ isPublished: e.target.checked })

  const premiumCheck = (
    <MuiCheckbox
      color='primary'
      checked={!!application.isPublished}
      onChange={updatePublished}
    />
  )

  return (
    <PublishedLabel
      label='Published?'
      control={premiumCheck}
      labelPlacement='end'
    />
  )
}

export default Published
