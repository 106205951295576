import produce from 'immer'
import { actionTypes } from './constants'

const initialState = {
  showDialog: false,
  showErrors: false,
  trialData: {
    systemId: null,
    contact: {
      fullName: null,
      email: null,
      phone: null,
      companyName: null,
      jobTitle: null,
      city: null,
      state: null,
      country: null,
    },
    purchasePlan: null,
    numberOfUsers: null,
    hasConsented: true,
  },
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.OPEN_TRIAL_DIALOG:
      draft.showDialog = true
      draft.trialData.systemId = action.payload.systemId
      break

    case actionTypes.CLOSE_TRIAL_DIALOG:
      draft.showDialog = false
      draft.showErrors = false
      draft.systemId = null
      break

    case actionTypes.UPDATE_TRIAL_DATA:
      Object.assign(draft.trialData, action.payload)
      break

    case actionTypes.CREATE_TRIAL_SUCCEEDED:
      break

    case actionTypes.CREATE_TRIAL_FAILED:
      draft.showErrors = true
      break

    // no default
  }
}, initialState)

export default reducer
