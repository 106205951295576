export const HIDDEN_FEATURES = [
  'PENS',
  'Deep Learning',
  'Coaching',
  'Can scan documents, courses, content, audio and video files and produce results',
  'Mobile coaching capabilities within an app',
  'Learner can select from a group of "experts" within the system',
  'Microsoft Teams Integration',
  'Knowledge reinforcement tool',
  'Business Intelligence integration',
  'Ad-hoc',
		'Single Sign On',
		'HRIS/ERP/Salesforce/Sharepoint/Other integration(s)'
]
