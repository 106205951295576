import mirrorCreator from 'mirror-creator'

export const actionTypes = mirrorCreator([
  'UPDATE_CURRENT_SEARCH_FILTERS_REQUESTED',
  'UPDATE_CURRENT_SEARCH_FILTERS_COMPLETED',
  'UPDATE_CURRENT_SEARCH_RESULTS_REQUESTED',
  'UPDATE_CURRENT_SEARCH_RESULTS_COMPLETED',

  'SAVE_CURRENT_SEARCH_REQUESTED',
  'SAVE_CURRENT_SEARCH_COMPLETED',
  'SAVE_CURRENT_SEARCH_FAILED',

  'FETCH_SEARCHES_REQUESTED',
  'FETCH_SEARCHES_SUCCEEDED',
  'FETCH_SEARCHES_FAILED',
], {prefix: 'searches/'})

export const initialFilters = {
  keywords: null,
  features: [],
  deliveryTypes: [],
  courseStandards: [],
  categories: [],
  contentTypes: [],
  languages: [],
  publishers: [],
  durations: []
}
