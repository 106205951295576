import React from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const Checkbox = ({ checked, handleChange, duration }) => {
  const { name } = duration
  return (
    <FormControlLabel
      style={{ marginLeft: 0, display: 'block' }}
      control={
        <MuiCheckbox
          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
          checkedIcon={<CheckBoxIcon fontSize='small' />}
          checked={checked}
          onChange={handleChange}
          name={name}
          color='primary'
          value={duration.id}
        />
      }
      label={<span style={{ fontSize: '14px' }}>{name}</span>}
    />
  )
}

export default Checkbox
