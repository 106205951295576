import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { usePasswordState } from 'users/hooks'
import { sendPasswordReset } from 'users/store/actions'
import Sent from './Sent'

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 6,
})

const Subtitle = styled('h4')({
  fontSize: 16,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 18,
})

const ErrorMessage = styled(Subtitle)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
})

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'space-between',
})

const emailSchema = yup.string().email().required()
const errorMessage = "Please enter a valid email address"

const RequestPasswordReset = (props) => {
  const { status } = usePasswordState()
  const dispatchRedux = useDispatch()
  const [email, setEmail] = useState()
  const [error, setError] = useState(false)
  const [done, setDone] = useState(false)

  if (done) return <Sent email={email} />

  const handleEmailChange = (event) => {
    const { value } = event.target

    setEmail(value)

    // if an error is showing, check to see if it's been fixed
    if (error) setError(!emailSchema.isValidSync(value))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (emailSchema.isValidSync(email)) {
      dispatchRedux(sendPasswordReset(email))
      setDone(true)
    } else {
      setError(true)
    }
  }

  let notice = <Subtitle>Enter the email associated with your account</Subtitle>
  if (status === 'failed') {
    notice = <ErrorMessage>The link you used was either invalid or expired. Please enter your email address to request a new one.</ErrorMessage>
  }

  return (
    <>
      <Title>Request Password Reset</Title>
      {notice}
      <Form onSubmit={handleSubmit} {...props}>
        <TextField
          label="Email address"
          type="email"
          defaultValue={email}
          onChange={handleEmailChange}
          variant="outlined"
          margin="dense"
          fullWidth
          error={error}
          helperText={error && errorMessage}
          autoFocus
        />
      </Form>
      <Actions>
        <Button
          color="secondary"
          component={Link}
          to="/login"
        >
          Back to Sign In
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Request Password Reset
        </Button>
      </Actions>
    </>
  )
}

export default RequestPasswordReset
