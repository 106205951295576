import React from 'react'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { styled } from '@material-ui/core'

const Content = styled('div')({
  fontSize: 14,
  margin: '0.75rem 0',
})

const InstilledPlayer = ({ instilledPlayer }) => {
  return (
    <>
     <SectionTitle>Content Playlist</SectionTitle>
     <Content>View and access our courses and content</Content>
      <iframe width="100%" height="500" allow="microphone; camera" frameBorder="0" src={instilledPlayer}></iframe>
    </>
  )
}

export default InstilledPlayer
