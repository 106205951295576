import React from 'react'
import { styled, TextField as MuiTextField } from '@material-ui/core'
import { useEditApplication } from 'contentPublisher/hooks'

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const CouseLibrary = props => {
  const [application, updateApplication] = useEditApplication()

  return (
    <Content {...props}>
      <MuiTextField
        defaultValue={application.courseLibrary}
        onChange={e => updateApplication({ courseLibrary: e.target.value })}
        label='Course Library'
        variant='outlined'
        multiline
        // rowsMax={12}
        maxRows={12}
        fullWidth
      />
    </Content>
  )
}

export default CouseLibrary
