import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import { useEditApplication } from 'contentPublisher/hooks'

const TrialLabel = styled(MuiFormControlLabel)({
  marginTop: 8,
})

const Trial = props => {
  const [application, updateApplication] = useEditApplication()

  const updateTrial = e =>
    updateApplication({ trial: e.target.checked })

  const trialCheck = (
    <MuiCheckbox
      color='primary'
      checked={!!application.trial}
      onChange={updateTrial}
    />
  )

  return (
    <TrialLabel
      label='Trial?'
      control={trialCheck}
      labelPlacement='end'
    />
  )
}

export default Trial
