import React from 'react'
import {styled} from '@material-ui/core/styles'
import ReactPlayer from 'react-player'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'

const Playlist = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const Video = styled(ReactPlayer)(({theme}) => ({
  marginBottom: 12,
}))

const playerConfig = {
  file: {
    attributes: {
      controls: true,
    },
  },
}

const Videos = ({videos=[]}) => {
  if (videos.length === 0) return null

  return (
    <>
      <SectionTitle>Videos</SectionTitle>
      <Playlist>
        {videos.map(({id, uri}) => <Video key={id} url={uri} config={playerConfig}/>)}
      </Playlist>
    </>
  )
}

export default Videos
