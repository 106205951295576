import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  TextField as MuiTextField,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'
import { businessSegmentList } from 'trials/store/constants'
import { useLabelWidth } from 'ui/hooks'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiSelect from '@material-ui/core/Select'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import { getShowErrors } from 'trials/store/selectors'

const FormControl = styled(MuiFormControl)({
  marginTop: 12,
  marginBottom: 12,
})

const OrganzationType = props => {
  const { organizationType } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()
  const showErrors = useSelector(getShowErrors)

  let error

  if (showErrors && !organizationType) {
    error = true
  }

  const handleSelect = e => {
    const organizationType = e.target.value
    dispatchRedux(updateTrialData({ organizationType }))
  }

  const [labelWidth, labelRef] = useLabelWidth()

  const input = (
    <MuiOutlinedInput
      id='orgSegment'
      labelWidth={labelWidth}
      name='orgSegment'
    />
  )

  const selectValue = organizationType || ''

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      error={error}
      fullWidth
      {...props}
    >
      <MuiInputLabel ref={labelRef} htmlFor='orgSegment'>
        Business/Org Segment
      </MuiInputLabel>
      <MuiSelect
        name='businessSegment'
        value={selectValue}
        onChange={handleSelect}
        input={input}
        style={{ width: '100%' }}
      >
        {businessSegmentList.map(item => (
          <MuiMenuItem key={item.value} value={item.value}>
            <span>{item.display}</span>
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default OrganzationType
