import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { styled } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Button as MuiButton } from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { handleCookieConsent } from 'users/store/actions'
import { useDispatch } from 'react-redux'
import publicIp from 'public-ip'
import history from 'utils/history'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '33rem',
    '&:focus': {
      outlineColor: '#fff',
    },
  },
}))

const ConsentText = styled('p')(({ theme }) => ({
  margin: '1.5rem',
  fontSize: '.75rem',
}))

const HyperLink = styled('a')(({ theme }) => ({
  margin: 0,
}))

export default function CookieModal() {
  const classes = useStyles()
  const user = useCurrentUser()
  const isGuestUser = !user

  const [cookies, setCookie] = useCookies(['hasCookieConsent'])
  const hasUserAllowedCookies = cookies.hasCookieConsent === 'true'
  const dispatchRedux = useDispatch()
  const acceptConsent = async () => {
    const usersIpAddress = await publicIp.v4()
    setCookie('hasCookieConsent', true, { path: '/' }, THIRTY_DAYS)
    dispatchRedux(
      handleCookieConsent({
        accepted: true,
        ipAddress: usersIpAddress,
        method: 'Accepting conditions in modal',
      })
    )
  }
  const declineConsent = () => {
    history.push('/register')
    dispatchRedux(
      handleCookieConsent({
        accepted: false,
      })
    )
  }
  const shouldRenderCookieModal = isGuestUser && !hasUserAllowedCookies
  return (
    shouldRenderCookieModal && (
      <Modal className={classes.modal} open={!hasUserAllowedCookies}>
        <div className={classes.paper}>
          <h2 style={{ marginBottom: '1rem', marginTop: 0 }}>Cookie Policy</h2>
          <ConsentText style={{ marginTop: 0 }}>
            This website or its third-party tools process personal data (e.g.
            browsing data or IP addresses) and use cookies or other identifiers,
            which are necessary for its functioning and required to achieve the
            purposes illustrated in the cookie policy. To find out more about
            the categories of personal information collected and the purposes
            for which such information will be used, please refer to our{' '}
            <HyperLink
              href='https://www.findcontent.io/privacy-policy/'
              target='_blank'
            >
              privacy policy.
            </HyperLink>{' '}
            You accept the use of cookies or other identifiers by pressing the
            ACCEPT button, closing or dismissing this notice or by continuing to
            browse otherwise.
          </ConsentText>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <MuiButton
              variant='contained'
              color='primary'
              onClick={declineConsent}
            >
              Decline
            </MuiButton>
            <MuiButton
              variant='contained'
              color='primary'
              onClick={acceptConsent}
            >
              Accept
            </MuiButton>
          </div>
        </div>
      </Modal>
    )
  )
}
