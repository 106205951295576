import React from 'react'
import {
  styled,
} from '@material-ui/core'
import Searches from './Searches'
import Leads from './Leads'
import ReportDialog from 'reports/components/Generate/Dialog'

const Content = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4),
}))

const VendorDashboard = (props) => {
  return (
    <Content>
      <Leads/>
      <Searches/>
      <ReportDialog/>
    </Content>
  )
}

export default VendorDashboard
