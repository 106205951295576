import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import { getApplicationsState } from 'contentPublisher/store/selectors'
import Card from 'contentPublisher/components/Card'
import CompareCard from 'contentPublisher/components/Compare/Card'
import { useCurrentUser } from 'users/hooks'
import TrialDialog from 'trials/components/Dialog'

const Content = styled('div')({
  marginTop: 20,
  marginBottom: 20,
})

const List = styled('ul')({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
})

const ListItem = styled('li')({
  marginBottom: 20,
})

const EmptyList = styled(MuiPaper)({
  padding: 50,
  fontSize: 18,
  textAlign: 'center',
})

const MyList = props => {
  const {
    data: { byId },
  } = useSelector(getApplicationsState)
  const { applications } = useCurrentUser()

  // TODO: store sorted IDs in Redux
  const nameSorter = (a, b) => {
    if (byId[a] && byId[b]) {
      return byId[a].name.localeCompare(byId[b].name)
    }
  }
  const sortedIds = applications.slice().sort(nameSorter)
  const contentPublisherFromIds = sortedIds.reduce((acc, id) => {
    const contentPublisher = byId[id]
    if (contentPublisher) acc.push(contentPublisher)
    return acc
  }, [])
  const doLmsExist = contentPublisherFromIds.length > 0

  const totalCount = applications.length
  const shouldShowEmptyList = totalCount === 0 || !doLmsExist

  return (
    <Content>
      <CompareCard />
      <List>
        {contentPublisherFromIds.map(contentPublisher => (
          <ListItem key={contentPublisher.id}>
            <Card contentPublisher={contentPublisher} />
          </ListItem>
        ))}
        <Collapse in={shouldShowEmptyList}>
          <li>
            <EmptyList>
              You haven't added any Content Publishers to your list.
            </EmptyList>
          </li>
        </Collapse>
      </List>
      <TrialDialog />
    </Content>
  )
}

export default MyList
