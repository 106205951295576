import React from 'react'
import { styled } from '@material-ui/core'
import { useMode } from 'app/hooks'
import Premium from './Premium'
import Admins from './Admins'
import Published from './Published'
import Trial from './Trial'

const Content = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: 20,
  marginBottom: 20,
}))

const Body = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridColumnGap: 20,

  // default fields
  '& > *': {
    gridColumn: '1 / 3',
  },

  [theme.breakpoints.up('md')]: {
    // ranking select
    '& > :nth-child(1)': {
      // gridColumn: '1',
    },

    // premium checkbox
    '& > :nth-child(2)': {
      // gridColumn: '2',
      // justifySelf: 'start',
    },
  },
}))

const TopBar = styled('div')({
  display: 'flex',
  justifyContent: 'start',
})



const SystemData = ({ contentPublisher, ...props }) => {
  const [mode] = useMode()
  const {isPremium} = contentPublisher
  const isAdmin = mode === 'admin' ? true : false

  if (mode !== 'admin' && mode !== 'vendor') return null

  

  return (
    <Content {...props}>
      <Body>
        <TopBar>
          {mode === 'admin' &&
          <Premium /> }
          <Published />          
          {(function() {   
              if (isPremium || isAdmin) {
                return <Trial/>
              }     
            }
          )()}
        </TopBar>
        <Admins />
      </Body>
    </Content>
  )
}

export default SystemData
