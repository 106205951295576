import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { actionTypes } from './constants'
import { refetchCurrentUser } from 'users/store/actions'
import { getCurrentUser } from 'users/store/selectors'
import { getTrialData } from './selectors'
import feathersClient from 'utils/feathers'
import { postMessage } from 'app/store/actions'

const trialsService = feathersClient.service('trials')

function* startTrialSaga(action) {
  const state = yield select()
  const trialData = getTrialData(state)
  const currentUser = getCurrentUser(state)

  const contact = {
    fullName:
      trialData.contact.fullName ||
      `${currentUser.firstName} ${currentUser.lastName}`,
    email: trialData.contact.email || currentUser.email,
    phone: trialData.contact.phone || currentUser.phone,
    companyName: trialData.contact.companyName || currentUser.companyName,
    jobTitle: trialData.contact.jobTitle || currentUser.jobTitle,
    city: trialData.contact.city || currentUser.city,
    state: trialData.contact.state || currentUser.state,
    country: trialData.contact.country || currentUser.country,
  }

  yield put({ type: actionTypes.UPDATE_TRIAL_DATA, payload: { contact } })
}

function* createTrialSaga(action) {
  const state = yield select()
  const trialData = getTrialData(state)

  try {
    yield call([trialsService, 'create'], {
      version: 2,
      ...trialData,
      contentPublisher: trialData.systemId,
    })

    yield put({
      type: actionTypes.CREATE_TRIAL_SUCCEEDED,
      payload: { systemId: trialData.systemId },
    })
    yield put({ type: actionTypes.CLOSE_TRIAL_DIALOG })
    yield put(postMessage({ message: 'Trial Requested' }))
    yield put(refetchCurrentUser())
  } catch (error) {
    console.log({ error })
    yield put({ type: actionTypes.CREATE_TRIAL_FAILED })
  }
}

function* watchOpenDialog() {
  yield takeLatest(actionTypes.OPEN_TRIAL_DIALOG, startTrialSaga)
}

function* watchcreateTrial() {
  yield takeEvery(actionTypes.CREATE_TRIAL_REQUESTED, createTrialSaga)
}

function* rootSaga() {
  yield all([watchOpenDialog(), watchcreateTrial()])
}

export default rootSaga
