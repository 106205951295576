import React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'
import { BUSINESS_SEGMENT_VALUES } from './constants'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  }
}))

const Value = styled('li')({})

const BusinessSegments = ({ businessSegments = [] }) => {
  const classes = useStyles()
  if (businessSegments.length === 0) return null

  return (
    <>
      <SectionTitle>Business Segments</SectionTitle>
      <ul className={classes.list}>
        {businessSegments.map(businessSegment => {
          const displayValue = BUSINESS_SEGMENT_VALUES[businessSegment]
          return <Value key={businessSegment}>{displayValue}</Value>
        })}
      </ul>
    </>
  )
}

export default BusinessSegments
