import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import MuiContainer from '@material-ui/core/Container'
import AdminRoute from 'app/components/AdminRoute'
import Layout from './Layout'
import Header from './Layout/Header'
import Main from './Layout/Main'
import Profile from 'users/components/Profile'
import Settings from 'users/components/Settings'
import ReportsDashboard from 'reports/components/Dashboard'
import Catalog from 'contentPublisher/components/FilteredList'
import MyList from 'contentPublisher/components/MyList'
import UsersAdmin from 'users/components/Admin'
import SystemsAdmin from 'contentPublisher/components/Admin'
import MessageBar from './MessageBar'
import {useMode} from 'app/hooks'
import GuestBlock from './GuestBlock'
import CookieConsentModal from 'ui/components/Modals/CookieModal'

const Dashboard = props => {
  const [mode] = useMode()

  const defaultRoute = () => {
    switch (mode) {
      case 'admin':
      case 'vendor':
        return <Redirect to='/reports' />

      case 'user':
        return <Redirect to='/catalog' />

      default:
        return <Redirect to='/catalog' />
    }
  }

  return (<>
    <Layout>
      <Header />
      <Main>
        <MuiContainer>
          <Switch>
            <Route path='/systems' component={MyList} exact />
            <Route path='/reports' component={ReportsDashboard} />
            <Route path='/catalog' component={Catalog} />
            <Route path='/profile' component={Profile} />
            <Route path='/settings' component={Settings} />

            <Route path='/systems' component={SystemsAdmin} />

            <AdminRoute path='/users' component={UsersAdmin} />

            <Route render={defaultRoute} />
          </Switch>
        </MuiContainer>
        <MessageBar />
      </Main>
						<CookieConsentModal/>
      <GuestBlock />
    </Layout>
  </>)
}

export default Dashboard
