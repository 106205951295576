import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core'
import {editApplicationBegin} from 'contentPublisher/store/actions'
import Form from './Form'

const Content = styled('div')(({theme}) => ({
  marginTop: 20,
  marginBottom: 20,
  padding: 20,
  backgroundColor: theme.palette.background.paper,
}))

const Edit = ({match}) => {
  const {id} = match.params
  const dispatchRedux = useDispatch()

  dispatchRedux(editApplicationBegin(id))

  return (
    <Content>
      <Form/>
    </Content>
  )
}

export default Edit
