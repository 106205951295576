import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import {Button as MuiButton} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

export default function ConfirmModal ({open, handleClose, handleConfirm}) {
  const classes = useStyles()

  const handleConfirmClick = () => {
    handleConfirm()
    handleClose()
  }

  return (
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <h2>Are you sure you want to delete this LMS?</h2>
          <div>
            <MuiButton onClick={handleConfirmClick}>Yes</MuiButton>
            <MuiButton onClick={handleClose}>No</MuiButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}
