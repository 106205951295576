import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))

const Migration = props => {
  const { migration } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateTrialData({ migration: e.target.value }))

  const error = !migration

  return (
    <Section title='Do you need to migrate your data?' error={error} {...props}>
      <RadioGroup value={migration || ''} onChange={handleChange}>
        <MuiFormControlLabel value='No' label='No' control={<MuiRadio />} />
        <MuiFormControlLabel value='Yes' label='Yes' control={<MuiRadio />} />
      </RadioGroup>
    </Section>
  )
}

export default Migration
