import {useSelector, useDispatch} from 'react-redux'
import {fetchUsers} from 'users/store/actions'
import {
  getCurrentUser,
  getAuthenticationState,
  getRegistrationState,
  getPasswordState,
  getUsers,
  getGuestUser
} from 'users/store/selectors'

export const useCurrentUser = () => {
  const currentUser = useSelector(getCurrentUser)

  return currentUser
}

export const useAuthenticationState = () => {
  const state = useSelector(getAuthenticationState)

  const isAuthenticated = state.status === 'authenticated'

  return {
    isAuthenticated,
    ...state
  }
}

export const useRegistrationState = () => {
  const state = useSelector(getRegistrationState)

  return state
}

export const usePasswordState = () => {
  const state = useSelector(getPasswordState)

  return state
}

export const useUsers = () => {
  const state = useSelector(getUsers)
  const dispatchRedux = useDispatch()

  if (!state.status) dispatchRedux(fetchUsers())

  return state
}
