import React, { useState } from 'react'
import { styled, Chip as MuiChip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {
  useCourseStandardState,
  useEditApplication,
} from 'contentPublisher/hooks'
import AddDialog from './AddDialog'

const Content = styled('div')({})

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

const Actions = styled('div')({})

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const Chip = styled(MuiChip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const ChipButton = styled(Chip)({
  cursor: 'pointer',
})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const CourseStandards = props => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useCourseStandardState()
  const [showDialog, setShowDialog] = useState(false)

  const appCourseStandards = application.courseStandards || []

  const removeCourseStandard = id => {
    const set = new Set(appCourseStandards)
    set.delete(id)
    updateApplication({ courseStandards: Array.from(set) })
  }

  const selectedCourseStandards = appCourseStandards
    .map(id => data.byId[id])
    .sort(nameSorter)

  let emptyChip
  if (selectedCourseStandards.length === 0)
    emptyChip = <Chip size='small' label='No course standard selected' />

  return (
    <Content {...props}>
      <Heading>
        <Title>Course Standard</Title>
        <Actions>
          <AddDialog open={showDialog} onClose={() => setShowDialog(false)} />
          <ChipButton
            label='Add Course Standard'
            size='small'
            color='primary'
            icon={<AddIcon />}
            onClick={() => setShowDialog(true)}
          />
        </Actions>
      </Heading>
      <Grid>
        {selectedCourseStandards.map(courseStandard => {
          return (
            <Chip
              size='small'
              key={courseStandard.id}
              label={courseStandard.name}
              onDelete={() => removeCourseStandard(courseStandard.id)}
            />
          )
        })}
        {emptyChip}
      </Grid>
    </Content>
  )
}

export default CourseStandards
