import React from 'react'
import {withRouter} from 'react-router-dom'
import {
  Button as MuiButton,
} from '@material-ui/core'

const BackButton = ({history, ...props}) => {
  const handleClick = () => {history.goBack()}

  return (
    <MuiButton onClick={handleClick}>Back</MuiButton>
  )
}

export default withRouter(BackButton)
