import React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import { useIndustriesState } from 'contentPublisher/hooks'
import SectionTitle from 'contentPublisher/components/Detail/SectionTitle'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  }
}))

const Value = styled('li')({})

const Industries = ({ industries = [] }) => {
  const { data } = useIndustriesState()
  const classes = useStyles()

  if (industries.length === 0) return null

  return (
    <>
      <SectionTitle>Industries</SectionTitle>
      <ul className={classes.list}>
        {industries.map(id => (
          <Value key={id}>{data.byId[id].name}</Value>
        ))}
      </ul>
    </>
  )
}

export default Industries
