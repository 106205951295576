import React from 'react'
import { useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Paper as MuiPaper
} from '@material-ui/core'
import { openReportDialog } from 'reports/store/actions'
import { useCounts } from 'reports/hooks'
import Loader from 'ui/components/Loader'
import Counts from 'reports/components/Counts'
import { useCurrentUser } from 'users/hooks'
import { useApplicationState } from 'contentPublisher/hooks'

const Content = styled(MuiPaper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2)
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const Title = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 24,
  color: theme.palette.text.secondary
}))

const Leads = props => {
  const { leads } = useCounts()
  const dispatchRedux = useDispatch()
  const { applications: applicationIds } = useCurrentUser()
  const { data } = useApplicationState()

  let isPremiumVendor = false

  if (data) {
    isPremiumVendor = applicationIds
      .map(id => data.byId[id])
      .some(app => app && app.isPremium)
  }

  const openDialog = () => dispatchRedux(openReportDialog('leads'))
  const doLeadsExist = leads.total !== undefined && leads.total !== null
  const content = doLeadsExist ? <Counts data={leads} /> : <Loader />

  let button
  if (isPremiumVendor) {
    button = (
      <MuiButton size='small' color='primary' onClick={openDialog}>
        Generate Report
      </MuiButton>
    )
  }

  return (
    <Content {...props}>
      <Header>
        <Title>Leads</Title>
        {button}
      </Header>
      {content}
    </Content>
  )
}

export default Leads
