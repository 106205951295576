import React from 'react'
import { styled } from '@material-ui/core/styles'

const Content = styled('div')({
  marginTop: 10,
})

const List = styled('ul')({})

const ListItem = styled('li')({
  marginBottom: '8px',
  fontSize: '18px',
})

const features = [
  'Save as a Favorite',
  'Request a Trial',
  'Unlimited searches, selections and comparisons',
  '100% FREE',
]

const FeatureList = () => {
  return (
    <Content>
      <List>
        {features.map(feature => (
          <ListItem key={feature}>{feature}</ListItem>
        ))}
      </List>
    </Content>
  )
}

export default FeatureList
