import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import MuiChip from '@material-ui/core/Chip'
import { useLabelWidth } from 'ui/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'
import { useContentTypeState } from 'contentPublisher/hooks'

const Fieldset = styled(({ theme, error, ...props }) => (
  <fieldset {...props} />
))(({ theme, error }) => ({
  margin: 0,
  borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled(({ theme, error, ...props }) => <legend {...props} />)(
  ({ theme, error }) => ({
    padding: 5,
    fontSize: 12,
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  })
)

const FormControl = styled(MuiFormControl)({
  margin: 0,
})

const SelectedContentTypes = styled('div')({
  marginTop: 5,
})

const Chip = styled(MuiChip)({
  marginTop: 5,
  marginBottom: 5,
  marginRight: 5,
})

const ContentType = styled(MuiMenuItem)({
  textTransform: 'capitalize'
})

const ellipsis = '\u2026'
const truncate = name => {
  if (name.length > 45) {
    return name.substring(0, 44) + ellipsis
  }

  return name
}

const Required = () => <span>&thinsp;*</span>

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '8px 12px 0',
  fontSize: 12,
}))

const ContentTypes = ({
  labelText = 'Content Types',
  required,
  error,
  ...props
}) => {
  const filters = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [labelWidth, labelRef] = useLabelWidth()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const { data, status } = useContentTypeState()

  const contentTypes = filters.contentTypes || 'all'

  if (status !== 'succeeded') return null

  // TODO: store sorted IDs in Redux
  const nameSorter = (a, b) =>
    data.byId[a].name.localeCompare(data.byId[b].name)
  const sortedIds = data.allIds.slice().sort(nameSorter)

  const items = []
  sortedIds.forEach(id => {
    const contentType = data.byId[id]
    items.push(
      <ContentType key={contentType.id} value={contentType.id}>
        {contentType.name}
      </ContentType>
    )
  })

  const addContentType = event => {
    const set = new Set(contentTypes)
    set.add(event.target.value)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({ contentTypes: Array.from(set) }))
  }

  const removeContentType = id => {
    const set = new Set(contentTypes)
    set.delete(id)
    dispatchRedux(updateCurrentSearchFilters({ contentTypes: Array.from(set) }))
  }

  const input = (
    <MuiOutlinedInput id='features' name='features' labelWidth={labelWidth} />
  )

  let errorMessage
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <>
      <Fieldset error={!!error} {...props}>
        <Legend error={!!error}>
          {labelText}
          {required ? <Required /> : null}
        </Legend>
        <FormControl variant='outlined' margin='dense' fullWidth>
          <MuiInputLabel ref={labelRef} htmlFor='features'>
            Choose...
          </MuiInputLabel>
          <MuiSelect
            name='features'
            value=''
            input={input}
            onChange={addContentType}
          >
            {items}
          </MuiSelect>
        </FormControl>
        <SelectedContentTypes>
          {contentTypes.map(id => {
            const contentType = data.byId[id]
            const label = truncate(contentType.name)
            const handleDelete = () => removeContentType(id)
            return (
              <Chip
                key={id}
                label={label}
                color='secondary'
                variant='outlined'
                onDelete={handleDelete}
              />
            )
          })}
        </SelectedContentTypes>
      </Fieldset>
      {errorMessage}
    </>
  )
}

export default ContentTypes
