import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import Filters from 'contentPublisher/components/Filters'
import List from './List'
import { useSelector } from 'react-redux'
import { getApplicationsState } from 'contentPublisher/store/selectors'

const Content = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 20,
  marginBottom: 20,
})

const StyledFilters = styled(Filters)({
  // position: 'sticky',
  top: 20,
  flex: '0 0 auto',
  marginRight: 20,
  minWidth: '23rem'
})

const FilteredList = props => {
  const { status } = useSelector(getApplicationsState)

  const hasFetchedApplications = status === 'succeeded'

  return (
    <Content>
      <StyledFilters />
      <List hasFetchedApplications={hasFetchedApplications} />
    </Content>
  )
}

export default FilteredList
