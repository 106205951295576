import moment from 'moment'
import stringify from 'csv-stringify/lib/sync'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import { getCurrentUser, getUsers } from 'users/store/selectors'
import { actionTypes as appActionTypes } from 'app/store/constants'
import { actionTypes as leadsActionTypes } from 'leads/store/constants'
import { actionTypes as searchesActionTypes } from 'searches/store/constants'
import { actionTypes as usersActionTypes } from 'users/store/constants'
import { actionTypes } from './constants'
// import {fetchApplications} from 'contentPublisher/store/actions'
import { fetchLeads } from 'leads/store/actions'
import { fetchSearches } from 'searches/store/actions'
import { fetchUsers } from 'users/store/actions'
import {
  getApplicationsState,
  getAudienceTypesState,
  getCourseStandardsState,
  getIndustriesState,
  getFeaturesState,
  getContentTypeState,
  getDurationState,
  getSubjectTypeState,
  getDeliveryTypesState,
  getLanguageState,
  getPublisherState,
} from 'contentPublisher/store/selectors'
import { getSearchesData } from 'searches/store/selectors'
import { getLeadsData } from 'leads/store/selectors'
import { getMode } from 'app/store/selectors'
import { getReportType, getReportOptions, getReportData } from './selectors'
import { reject, values } from 'lodash'
import { duration } from '@material-ui/core'

const usersService = feathersClient.service('users')
const applicationsService = feathersClient.service('contentpublishers')
const searchesService = feathersClient.service('searches')
const leadsService = feathersClient.service('leads')
const trialsService = feathersClient.service('trials')

function* resetCountsSaga(action) {
  yield put({ type: actionTypes.RESET_COUNTS })
}

function* fetchCountsSaga(action) {
  const state = yield select()
  const mode = getMode(state)
  const user = getCurrentUser(state)

  const oneWeekAgo = moment().subtract(7, 'days').startOf('day')
  const oneMonthAgo = moment().subtract(1, 'month').startOf('day')


  const leadsQuery = {
    version: 2,
    application: mode === 'vendor' ? { $in: user.applications } : undefined,
    $limit: 0,
  }

  const searchesQuery = {
    version: 2,
    applications: mode === 'vendor' ? { $in: user.applications } : undefined,
    $limit: 0,
  }
  const test = yield call([leadsService, 'find'])

  const leads = {
    total: (yield call([leadsService, 'find'], { query: leadsQuery })).total,
    newThisWeek: (yield call([leadsService, 'find'], {
      query: { ...leadsQuery, createdAt: { $gt: oneWeekAgo } },
    })).total,
    newThisMonth: (yield call([leadsService, 'find'], {
      query: { ...leadsQuery, createdAt: { $gt: oneMonthAgo } },
    })).total,
  }

  const searches = {
    total: (yield call([searchesService, 'find'], { query: searchesQuery }))
      .total,
    newThisWeek: (yield call([searchesService, 'find'], {
      query: { ...searchesQuery, createdAt: { $gt: oneWeekAgo } },
    })).total,
    newThisMonth: (yield call([searchesService, 'find'], {
      query: { ...searchesQuery, createdAt: { $gt: oneMonthAgo } },
    })).total,
  }

  const trials = {
    total: (yield call([trialsService, 'find'], { query: searchesQuery }))
      .total,
    newThisWeek: (yield call([trialsService, 'find'], {
      query: { ...searchesQuery, createdAt: { $gt: oneWeekAgo } },
    })).total,
    newThisMonth: (yield call([trialsService, 'find'], {
      query: { ...searchesQuery, createdAt: { $gt: oneMonthAgo } },
    })).total,
  }

  const users = {
    total: (yield call([usersService, 'find'], { query: { $limit: 0 } })).total,
    admin: (yield call([usersService, 'find'], {
      query: { role: 'admin', $limit: 0 },
    })).total,
    vendor: (yield call([usersService, 'find'], {
      query: { role: 'vendor', $limit: 0 },
    })).total,
    newThisWeek: (yield call([usersService, 'find'], {
      query: { createdAt: { $gt: oneWeekAgo }, $limit: 0 },
    })).total,
    newThisMonth: (yield call([usersService, 'find'], {
      query: { createdAt: { $gt: oneMonthAgo }, $limit: 0 },
    })).total,
  }

  const systems = {
    total: (yield call([applicationsService, 'find'], { query: { $limit: 0 } }))
      .total,
    newThisWeek: (yield call([applicationsService, 'find'], {
      query: { createdAt: { $gt: oneWeekAgo }, $limit: 0 },
    })).total,
    newThisMonth: (yield call([applicationsService, 'find'], {
      query: { createdAt: { $gt: oneMonthAgo }, $limit: 0 },
    })).total,
    premium: (yield call([applicationsService, 'find'], {
      query: { isPremium: true, $limit: 0 },
    })).total,
  }

  yield put({
    type: actionTypes.FETCH_COUNTS_SUCCEEDED,
    payload: { leads, searches, users, systems, trials },
  })
}

function* fetchReportDataSaga(reportType) {
  const fetchTypes = {}

  let fetchTypeToCheck

  switch (reportType) {
    case 'leads':
      yield put(fetchLeads())
      fetchTypeToCheck = leadsActionTypes.FETCH_LEADS_SUCCEEDED
      break
    case 'searches':
      yield put(fetchSearches())
      fetchTypeToCheck = searchesActionTypes.FETCH_SEARCHES_SUCCEEDED
      break
    case 'popularSearches':
      yield put(fetchSearches())
      fetchTypeToCheck = searchesActionTypes.FETCH_SEARCHES_SUCCEEDED
      break
    case 'contentPublisherCount':
      yield put(fetchSearches())
      fetchTypeToCheck = searchesActionTypes.FETCH_SEARCHES_SUCCEEDED
      break
    case 'users':
      yield put(fetchUsers())
      fetchTypeToCheck = usersActionTypes.FETCH_USERS_SUCCEEDED
      break
  }

  fetchTypes[fetchTypeToCheck] = true

  let stillFetching = true
  while (stillFetching) {
    const action = yield take(fetchTypeToCheck)
    if (action.payload.completed) fetchTypes[action.type] = false
    stillFetching = fetchTypes[fetchTypeToCheck]
  }

  yield put({ type: actionTypes.REPORT_DATA_LOADED })
}

function* generateReportSaga(action) {
  const state = yield select()
  const reportType = getReportType(state)
  const reportData = getReportData(state)

  // skip if no report type
  if (!reportType) return

  if (!reportData.loaded) yield fetchReportDataSaga(reportType)

  if (reportData.url) window.URL.revokeObjectURL(reportData.url)

  const type = reportType
  const name = yield generateReportName()
  const csv = yield generateCsv()
  const blob = new Blob([csv], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)

  const data = {
    type,
    name,
    csv,
    url,
    ready: true,
  }

  yield put({ type: actionTypes.REPORT_UPDATED, payload: { data } })
}

function* generateReportName() {
  const state = yield select()
  const reportType = getReportType(state)
  const reportOptions = getReportOptions(state)

  let name = `${reportType}`

  if (reportOptions.range !== 'all') {
    const startDateStr = moment(reportOptions.startDate).format('YYYYMMDD')
    const endDateStr = moment(reportOptions.endDate).format('YYYYMMDD')

    name += `_${startDateStr}`
    if (endDateStr !== startDateStr) name += `-${endDateStr}`
  }

  name += '.csv'

  return name
}

function* generateCsv() {
  const state = yield select()
  const reportType = getReportType(state)

  switch (reportType) {
    case 'leads':
      return yield generateLeadsCsv()

    case 'searches':
      return yield generateSearchesCsv()

    case 'popularSearches':
      return yield generatePopularSearchesCsv()

    case 'contentPublisherCount':
      return yield generateContentPublisherCsv()

    case 'users':
      return yield generateUsersCsv()

    // no default
  }
}

function* generateLeadsCsv() {
  const state = yield select()
  const mode = getMode(state)
  const currentUser = getCurrentUser(state)
  const reportOptions = getReportOptions(state)

  const leadsData = getLeadsData(state)
  const { data: usersData } = getUsers(state)
  const { data: appsData } = getApplicationsState(state)

  let leads, csvOptions
  if (mode === 'admin') {
    csvOptions = {
      header: true,
      columns: [
        'id',
        'createdAt',
        'updatedAt',
        'system',
        'sources',
        'user',
        'company',
        'jobTitle',
        'email',
        'phone',
        'city',
        'state',
        'country',
        'usageType',
        'usageTypeExplanation',
        'everPurchased',
        'contentNeeded',
      ],
    }

    leads = Object.values(leadsData.byId)

    if (reportOptions.range !== 'all') {
      leads = leads.filter(l =>
        moment(l.updatedAt).isBetween(
          reportOptions.startDate,
          reportOptions.endDate,
          'day',
          '[]'
        )
      )
    }

    leads = leads.reduce((acc, lead) => {
      try {
        const user = usersData.byId[lead.user] || {}
        const app = appsData.byId[lead.application]
        const system = app && app.name
        const triggers = new Set(
          lead.actions.map(a =>
            a.trigger === 'RFP Submitted' ? 'Requested Quote' : a.trigger
          )
        )

        if (system) {
          acc.push({
            ...lead,
            createdAt: moment(lead.createdAt).format('YYYY-MM-DD'),
            updatedAt: moment(lead.updatedAt).format('YYYY-MM-DD'),
            system,
            sources: Array.from(triggers),
            user: `${user.firstName} ${user.lastName}`,
            company: user.companyName,
            jobTitle: user.jobTitle,
            email: user.email,
            phone: user.phone,
            city: user.city,
            state: user.state,
            country: user.country,
            usageType: user.usageType,
            usageTypeExplanation: user.usageTypeExplanation,
            everPurchased: user.everPurchased,
            contentNeeded: user.contentNeeded,
          })
        }
      } catch (error) {
        // output empty row
        return {}
      }
      return acc
    }, [])
  } else {
    csvOptions = {
      header: true,
      columns: [
        'createdAt',
        'updatedAt',
        'system',
        'sources',
        'leadName',
        'leadCompany',
        'leadJobTitle',
        'leadEmail',
        'leadPhone',
        'leadCity',
        'leadState',
        'leadCountry',
        'leadUsageType',
        'leadUsageTypeExplanation',
        'leadEverPurchased',
        'contentNeeded',
        'otherSystems',
        'comparedSystems',        
      ],
    }

    leads = []

    const allLeads = Object.values(leadsData.byId)
    const allSystems = Object.values(appsData.byId)

    const vendorSystems = allSystems.filter(
      s => s.admins && s.admins.includes(currentUser.id)
    )

    for (const system of vendorSystems) {
      let systemLeads = allLeads.filter(l => l.application === system.id)

      if (reportOptions.range !== 'all') {
        systemLeads = systemLeads.filter(l =>
          moment(l.updatedAt).isBetween(
            reportOptions.startDate,
            reportOptions.endDate,
            'day',
            '[]'
          )
        )
      }

      for (const lead of systemLeads) {
        try {          
          const leadUser = usersData.byId[lead.user] || {}
          const triggers = new Set(
            lead.actions.map(a =>
              a.trigger === 'RFP Submitted' ? 'Requested Quote' : a.trigger
            )
          )
          const comparedLeadActions = lead.actions.filter(
            lead => lead.trigger === 'Compared'
          )
          const comparedSystems = comparedLeadActions.reduce(
            (acc, leadActions) => {
              const { otherSystems } = leadActions
              if (otherSystems) {
                otherSystems.forEach(id => acc.push(appsData.byId[id].name))
              }
              return acc
            },
            []
          )
          const compareLeads = allLeads.reduce((acc, l) => {
            const { actions, application } = l
            const areActionsOtherThanCompare = actions.some(
              action => action.trigger === 'Compared'
            )
            if (areActionsOtherThanCompare) {
              acc.push(application)
            }
            return acc
          }, [])
          const otherSystemIds = allLeads
            .filter(l => l.user === lead.user)
            .map(l => l.application)
          const otherSystemIdsWithNames = otherSystemIds.filter(id => {
            const isCompareLead = compareLeads.includes(id)
            return !isCompareLead && Boolean(appsData.byId[id])
          })

          leads.push({
            ...lead,
            createdAt: moment(lead.createdAt).format('YYYY-MM-DD'),
            updatedAt: moment(lead.updatedAt).format('YYYY-MM-DD'),
            system: system.name,
            sources: Array.from(triggers),
            leadName: `${leadUser.firstName} ${leadUser.lastName}`,
            leadCompany: leadUser.companyName,
            leadJobTitle: leadUser.jobTitle,
            leadEmail: leadUser.email,
            leadPhone: leadUser.phone,
            leadCity: leadUser.city,
            leadState: leadUser.state,
            leadCountry: leadUser.country,
            leadUsageType: leadUser.usageType,
            leadUsageTypeExplanation: leadUser.usageTypeExplanation,
            leadEverPurchased: leadUser.everPurchased,
            contentNeeded: leadUser.contentNeeded,
            otherSystems: otherSystemIdsWithNames.map(
              id => appsData.byId[id].name
            ),
            comparedSystems: [...new Set(comparedSystems)].map(
              system => system
            ),
          })
        } catch (error) {
          // don't output row
        }
      }
    }

//

  }

  return stringify(leads, csvOptions)
}

function* generateContentPublisherCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)
  const mode = getMode(state)
  const isVendor = mode === 'vendor'
  const currentUser = getCurrentUser(state)

  const searchesData = getSearchesData(state)
  const { data: usersData } = getUsers(state)
  const { data: appsData } = getApplicationsState(state)

  const csvOptions = {
    header: true,
    columns: ['Content Publisher', 'Count'],
  }

  const getSearches = () => {
    let searchesToRender = Object.values(searchesData.byId)
    if (isVendor) {
      const { applications: vendorApplications } = currentUser
      const searchesFromVendorApplications = searchesToRender.filter(search => {
        const { applications: applicationsInSearch } = search
        return applicationsInSearch.some(applicationInSearch =>
          vendorApplications.includes(applicationInSearch)
        )
      })
      searchesToRender = searchesFromVendorApplications
    }
    return searchesToRender
  }

  let searches = getSearches()

  if (reportOptions.range !== 'all') {
    searches = searches.filter(s =>
      moment(s.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  const searchesGrouped = searches.reduce((acc, search) => {
    const { applications } = search
    applications.forEach(contentPublisherId => {
      const contentPublisherName = appsData.byId[contentPublisherId].name
      const alreadyExist = acc[contentPublisherName]
      if (alreadyExist) {
        acc[contentPublisherName] = acc[contentPublisherName] + 1
      } else {
        acc[contentPublisherName] = 1
      }
    })
    return acc
  }, {})

  searches = Object.keys(searchesGrouped).map(contentPublisher => ({
    'Content Publisher': contentPublisher,
    Count: searchesGrouped[contentPublisher],
  }))

  return stringify(searches, csvOptions)
}

function* generateSearchesCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)
  const mode = getMode(state)
  const isVendor = mode === 'vendor'
  const currentUser = getCurrentUser(state)

  const searchesData = getSearchesData(state)
  const { data: usersData } = getUsers(state)
  const { data: appsData } = getApplicationsState(state)
  const { data: featuresData } = getFeaturesState(state)
  const { data: contentTypes } = getContentTypeState(state)
  const { data: durations } = getDurationState(state)
  const { data: categories } = getSubjectTypeState(state)
  const { data: deliveryTypes } = getDeliveryTypesState(state)
  const { data: languages } = getLanguageState(state)
  const { data: publishers } = getPublisherState(state)
  const { data: courseStandards } = getCourseStandardsState(state)

  const csvOptions = {
    header: true,
    columns: isVendor
      ? [
          'createdAt',
          'updatedAt',
          'user',
          'company',
          'jobTitle',
          'email',
          'phone',
          'source',
          'filters',
          'contentProviders',
        ]
      : [
          'id',
          'createdAt',
          'updatedAt',
          'user',
          'company',
          'jobTitle',
          'email',
          'phone',
          'source',
          'filters',
          'contentProviders',
        ],
  }

  const getSearches = () => {
    let searchesToRender = Object.values(searchesData.byId)
    if (isVendor) {
      const { applications: vendorApplications } = currentUser
      const searchesFromVendorApplications = searchesToRender.filter(search => {
        const { applications: applicationsInSearch } = search
        return applicationsInSearch.some(applicationInSearch =>
          vendorApplications.includes(applicationInSearch)
        )
      })
      searchesToRender = searchesFromVendorApplications
    }
    return searchesToRender
  }

  let searches = getSearches()

  if (reportOptions.range !== 'all') {
    searches = searches.filter(s =>
      moment(s.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  searches = searches.map(search => {
    try {
      const user = usersData.byId[search.user] || {}
      const contentProviders = search.applications.map(
        id => appsData.byId[id].name
      )

      const filters = {}
      for (let [key, value] of Object.entries(search.filters)) {
        switch (key) {
          case 'categories':
            value = value.map(id => categories.byId[id].name)
            break

          case 'contentTypes':
            value = value.map(id => contentTypes.byId[id].name)
            break

          case 'deliveryTypes':
            value = value.map(id => deliveryTypes.byId[id].name)
            break

          case 'durations':
            value = value.map(id => durations.byId[id].name)
            break

          case 'languages':
            value = value.map(id => languages.byId[id].name)
            break

          case 'publishers':
            value = value.map(id => publishers.byId[id].name)
            break

          case 'courseStandards':
              value = value.map(id => courseStandards.byId[id].name)
              break

          case 'features':
            value = value.map(id => featuresData.byId[id].name)
            break

          // no default
        }

        filters[key] = value
      }

      return {
        ...search,
        createdAt: moment(search.createdAt).format('YYYY-MM-DD'),
        updatedAt: moment(search.updatedAt).format('YYYY-MM-DD'),
        filters,
        user: `${user.firstName} ${user.lastName}`,
        company: user.companyName,
        jobTitle: user.jobTitle,
        email: user.email,
        phone: user.phone,
        contentProviders,
      }
    } catch (error) {
      // output empty row
      return {}
    }
  })

  return stringify(searches, csvOptions)
}

function* generatePopularSearchesCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)
  const mode = getMode(state)
  const currentUser = getCurrentUser(state)
  const isVendor = mode === 'vendor'

  const searchesData = getSearchesData(state)

  const { data: featuresData } = getFeaturesState(state)
  const { data: contentTypes } = getContentTypeState(state)
  const { data: durations } = getDurationState(state)
  const { data: categories } = getSubjectTypeState(state)
  const { data: deliveryTypes } = getDeliveryTypesState(state)
  const { data: languages } = getLanguageState(state)
  const { data: publishers } = getPublisherState(state)
  const { data: courseStandards } = getCourseStandardsState(state)

  const csvOptions = {
    header: true,
    columns: ['Filter Type', 'Value', 'Count'],
  }

  const getSearches = () => {
    let searchesToRender = Object.values(searchesData.byId)
    if (isVendor) {
      const { applications: vendorApplications } = currentUser
      const searchesFromVendorApplications = searchesToRender.filter(search => {
        const { applications: applicationsInSearch } = search
        return applicationsInSearch.some(applicationInSearch =>
          vendorApplications.includes(applicationInSearch)
        )
      })
      searchesToRender = searchesFromVendorApplications
    }
    return searchesToRender
  }

  let searches = getSearches()

  if (reportOptions.range !== 'all') {
    searches = searches.filter(s =>
      moment(s.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  const getValuesFromValueArray = ({
    acc,
    values,
    getIndexOfExistingFilter,
    filterType,
    typeData,
  }) => {
    values.forEach(value => {
      const valueForReport = typeData.byId[value] && typeData.byId[value].name
      if (valueForReport) {
        const indexOfExistingFilter = getIndexOfExistingFilter({
          filterType,
          value: valueForReport,
        })
        const hasValueBeenCounted = indexOfExistingFilter > -1
        if (hasValueBeenCounted) {
          const existingSearch = acc[indexOfExistingFilter]
          existingSearch['Count'] = existingSearch['Count'] + 1
        } else {
          acc.push({
            'Filter Type': filterType,
            Value: valueForReport,
            Count: 1,
          })
        }
      }
    })
  }

  const searchFilters = searches.map(search => search.filters)
  const searchResultsForCsv = searchFilters.reduce((acc, filter) => {
    // get the index of the existing counted filter if it exist
    // if it does not exist or it has not been counted, this returns -1
    const getIndexOfExistingFilter = ({ filterType, value }) =>
      acc.findIndex(
        countedSearch =>
          countedSearch['Filter Type'] === filterType &&
          (countedSearch['Value'] === value ||
            JSON.stringify(countedSearch['Value']) === JSON.stringify(value) ||
            JSON.stringify(countedSearch['Value']).toLowerCase() ===
              JSON.stringify(value).toLowerCase())
      )

    // converting the object of filters to a list of filter types
    const filterTypes = Object.keys(filter)
    // looping over each filter type and getting the value
    filterTypes.map(filterType => {
      let value = filter[filterType]
      if (value) {
        const isValueArray = Array.isArray(value)
        const isValueObject = typeof value === 'object'
        if (isValueArray) {
          switch (filterType) {
            case 'features':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'features',
                typeData: featuresData,
              })
              break
            case 'deliveryTypes':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'deliveryTypes',
                typeData: deliveryTypes,
              })
              break
            case 'categories':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'cetegories',
                typeData: categories,
              })
              break
            case 'contentTypes':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'contentTypes',
                typeData: contentTypes,
              })
              break
            case 'languages':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'languages',
                typeData: languages,
              })
              break
            case 'publishers':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'publishers',
                typeData: publishers,
              })
              break
            case 'durations':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'durations',
                typeData: durations,
              })
              break
            case 'courseStandards':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'courseStandards',
                typeData: courseStandards,
              })
              break
            case 'deploymentTypes':
              value.map(deploymentType => {
                const indexOfExistingFilter = getIndexOfExistingFilter({
                  filterType: 'deploymentTypes',
                  value: deploymentType,
                })
                const hasValueBeenCounted = indexOfExistingFilter > -1
                if (hasValueBeenCounted) {
                  const existingSearch = acc[indexOfExistingFilter]
                  existingSearch['Count'] = existingSearch['Count'] + 1
                } else {
                  acc.push({
                    'Filter Type': 'deploymentTypes',
                    Value: deploymentType,
                    Count: 1,
                  })
                }
              })
              break

            // no default
          }
        } else if (isValueObject && value !== null) {
          const valueObjectKeys = Object.keys(value)
          const doesValueObjectExist = valueObjectKeys.some(objectKey =>
            Boolean(value[objectKey])
          )
          if (doesValueObjectExist) {
            const minValue = value['min']
            const maxValue = value['max']
            const valueForReport = maxValue
              ? `${Number(minValue.toFixed(2)).toLocaleString(
                'en-US'
              )}-${Number(maxValue.toFixed(2)).toLocaleString('en-US')}`
              : `${Number(minValue.toFixed(2)).toLocaleString('en-US')}`
            const indexOfExistingFilter = getIndexOfExistingFilter({
              filterType,
              value: valueForReport,
            })
            const hasFeatureBeenCounted = indexOfExistingFilter > -1
            if (hasFeatureBeenCounted) {
              const existingSearch = acc[indexOfExistingFilter]
              existingSearch['Count'] = existingSearch['Count'] + 1
            } else {
              acc.push({
                'Filter Type': filterType,
                Value: valueForReport,
                Count: 1,
              })
            }
          }
        } else {
          const indexOfExistingFilter = getIndexOfExistingFilter({
            filterType,
            value,
          })
          const doesExistingFilterTypeExist = indexOfExistingFilter > -1
          if (doesExistingFilterTypeExist) {
            const existingSearch = acc[indexOfExistingFilter]
            existingSearch['Count'] = existingSearch['Count'] + 1
          } else {
            const newlyCountedSearch = {
              'Filter Type': filterType,
              Value: value,
              Count: 1,
            }
            acc.push(newlyCountedSearch)
          }
        }
      }
    })
    // returning the sorted array by count (largest to smallest)
    return acc.sort((a, b) => b.Count - a.Count)
  }, [])

  return stringify(searchResultsForCsv, csvOptions)
}

function* generateUsersCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)

  const { data: usersData } = getUsers(state)
  const { data: appsData } = getApplicationsState(state)

  const csvOptions = {
    header: true,
    columns: [
      'id',
      'createdAt',
      'updatedAt',
      'isVerified',
      'firstName',
      'lastName',
      'companyName',
      'jobTitle',
      'email',
      'phone',
      'city',
      'state',
      'country',
      'favorites',
      'usageType',
      'usageTypeExplanation',
      // 'contactConsent',
      'everPurchased',
      'contentNeeded',
    ],
  }

  let users = Object.values(usersData.byId)

  if (reportOptions.range !== 'all') {
    users = users.filter(u =>
      moment(u.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  users = users.map(user => {
    try {
      const favorites = user.applications.map(id => appsData.byId[id].name)
      const isVerified = user.isVerified ? 'true' : 'false'

      return {
        ...user,
        createdAt: moment(user.createdAt).format('YYYY-MM-DD'),
        updatedAt: moment(user.updatedAt).format('YYYY-MM-DD'),
        favorites,
        isVerified,
      }
    } catch (error) {
      // output empty row
      return {}
    }
  })

  return stringify(users, csvOptions)
}

function* watchModeChange() {
  yield takeLatest(appActionTypes.SET_MODE, resetCountsSaga)
}

function* watchFetchCounts() {
  yield takeLatest(actionTypes.FETCH_COUNTS_REQUESTED, fetchCountsSaga)
}

function* watchGenerateReport() {
  const generateReportActions = [
    actionTypes.OPEN_REPORT_DIALOG,
    actionTypes.SET_REPORT_OPTIONS,
  ]

  yield takeLatest(generateReportActions, generateReportSaga)
}

function* rootSaga() {
  yield all([watchModeChange(), watchFetchCounts(), watchGenerateReport()])
}

export default rootSaga
