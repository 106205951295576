import React from 'react'
import { styled } from '@material-ui/core/styles'
import MuiGrid from '@material-ui/core/Grid'
import Logo from 'ui/components/Logo'

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    padding: 24,
    paddingBottom: 12,
  },
}))

const StyledLogo = styled(Logo)(({ theme }) => ({
  paddingTop: 48,
  paddingBottom: 24,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
  '&:hover': {
    cursor: 'pointer',
  },
}))

const goToMarketingSite = () => {
  const MARKETING_SITE_URL = 'https://www.findcontent.io/'
  window.location = MARKETING_SITE_URL
}

const Info = ({ children, ...props }) => {
  return (
    <MuiGrid item xs={12} md={4} lg={5} {...props}>
      <Content>
        <StyledLogo onClick={goToMarketingSite} />
        {children}
      </Content>
    </MuiGrid>
  )
}

export default Info
