import React from 'react'
import { styled, Collapse as MuiCollapse } from '@material-ui/core'

const Content = styled('div')({
  marginBottom: '.25rem',
})

const Contact = styled('div')({
  marginTop: 12,
})

const ContactName = styled('div')({
  fontWeight: 500,
})

const ContactJobTitle = styled('div')({
  fontStyle: 'italic',
})

const ContactEmail = styled('div')({})
const ContactPhone = styled('div')({})

const ContactInfo = ({ contentPublisher, expanded, ...props }) => {
  const { contact = {} } = contentPublisher

  return (
    <Content {...props}>
      <MuiCollapse in={expanded}>
        <Contact>
          <ContactName>{contact.fullName}</ContactName>
          <ContactJobTitle>{contact.jobPosition}</ContactJobTitle>
          <ContactEmail>{contact.email}</ContactEmail>
          <ContactPhone>{contact.phone}</ContactPhone>
        </Contact>
      </MuiCollapse>
    </Content>
  )
}

export default ContactInfo
