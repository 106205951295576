import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  TextField as MuiTextField,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))

const Group = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const CurrentSystem = props => {
  const { currentSystem, currentVendor } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()

  const disableText = !currentSystem || currentSystem === 'No'

  const handleChange = event => {
    let { value } = event.target

    dispatchRedux(updateTrialData({ currentSystem: value }))

    if (value === 'No') dispatchRedux(updateTrialData({ currentVendor: null }))
  }

  const handleText = e =>
    dispatchRedux(updateTrialData({ currentVendor: e.target.value }))

  const error = !currentSystem

  return (
    <Section
      title='Do you currently use other content providers?'
      error={error}
      {...props}
    >
      <RadioGroup value={currentSystem || ''} onChange={handleChange}>
        <MuiFormControlLabel value='No' label='No' control={<MuiRadio />} />
        <Group>
          <MuiFormControlLabel value='Yes' label='Yes' control={<MuiRadio />} />
          <MuiTextField
            placeholder='Who?'
            disabled={disableText}
            value={currentVendor || ''}
            onChange={handleText}
          />
        </Group>
      </RadioGroup>
    </Section>
  )
}

export default CurrentSystem
