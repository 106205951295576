import { useSelector } from 'react-redux'
import { getCompareSystemIds } from 'contentPublisher/store/selectors'
import {
  useSystemData,
  useContentTypeState,
  useDurationState,
  useSubjectTypesState,
  useDeliveryTypesState,
  useLangaugeState,
  usePublisherState,
  useFeaturesState,
  useCourseStandardState
} from 'contentPublisher/hooks'

export const useCompareData = () => {
  const systemIds = useSelector(getCompareSystemIds)

  let systems = []
  systems[0] = useSystemData(systemIds[0])
  systems[1] = useSystemData(systemIds[1])
  systems[2] = useSystemData(systemIds[2])
  systems[3] = useSystemData(systemIds[3])

  // filter empty systems
  systems = systems.filter(s => s)

  const { data: contentTypes } = useContentTypeState()
  const { data: durations } = useDurationState()
  const { data: categories } = useSubjectTypesState()
  const { data: deliveryTypes } = useDeliveryTypesState()
  const { data: languages } = useLangaugeState()
  const { data: publishers } = usePublisherState()
  const { data: featuresData } = useFeaturesState()
  const { data: courseStandards } = useCourseStandardState()

  return {
    systems,
    contentTypes,
    durations,
    categories,
    deliveryTypes,
    languages,
    publishers,
    featuresData,
    courseStandards
  }
}
