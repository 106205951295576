import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthenticationState } from 'users/hooks'
import { useAppReadyState } from 'contentPublisher/hooks'

const UserRoute = ({ redirect = '/login', component: Component, ...rest }) => {
  const { status: authStatus } = useAuthenticationState()
  const isAppReady = useAppReadyState()
  // switch (authStatus) {
  //   case 'authenticated':
  //     return isAppReady ? <Route {...props}/> : null

  //   case 'authenticating':
  //     return null

  //   case 'unauthenticated':
  //   case 'failed':
  //   default:
  //     const to = {
  //       pathname: redirect,
  //       state: {
  //         from: {...props.location}
  //       },
  //     }

  //     return <Redirect to={to}/>
  // }
  if (authStatus === 'failed') {
    const to = {
      pathname: redirect,
      state: {
        from: { ...rest.location }
      }
    }
    return <Redirect to={to} />
  } else {
    return isAppReady ? (
      <Route {...rest} render={props => <Component {...props} />} />
    ) : null
  }
}

export default UserRoute
