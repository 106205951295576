import React from 'react'
import { useDispatch } from 'react-redux'
import { styled, Button as MuiButton } from '@material-ui/core'
import MuiBookmarkIcon from '@material-ui/icons/Bookmark'
import MuiBookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import { useCurrentUser } from 'users/hooks'
import { addLmsToList, removeLmsFromList } from 'users/store/actions'
import { useMode } from 'app/hooks'
import Tooltip from '@material-ui/core/Tooltip'
import history from 'utils/history'

const Button = styled(MuiButton)(({ theme }) => ({
  textTransform: 'none',
}))

const BookmarkIcon = styled(MuiBookmarkIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const BookmarkBorderIcon = styled(MuiBookmarkBorderIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const SaveButton = ({ contentPublisher, ...props }) => {
  const [mode] = useMode()
  const user = useCurrentUser()
  const dispatchRedux = useDispatch()

  const isGuestUser = !user

  const { id, admins } = contentPublisher

  const inList = user && user.applications.includes(id)

  switch (mode) {
    
    case 'vendor':
      if (admins.includes(user.id)) return null

    // no default
  }

  const buttonIcon = isGuestUser ? (
    <BookmarkBorderIcon style={{ color: '#D3D3D3' }} />
  ) : inList ? (
    <BookmarkIcon />
  ) : (
    <BookmarkBorderIcon />
  )
  const buttonText = isGuestUser ? 'Save' : inList ? 'Saved' : 'Save'

  const handleClick = event => {
    event.stopPropagation()
    if (!isGuestUser) {
      const action = inList ? removeLmsFromList(id) : addLmsToList([id])
      dispatchRedux(action)
    } else {
      history.push('/register')
    }
  }

  return (
    <Tooltip
      title={'Create a FREE account to access this feature'}
      disableHoverListener={!isGuestUser}
    >
      <Button
        color='primary'
        onClick={handleClick}
        style={{
          color: isGuestUser ? '#D3D3D3' : '',
        }}
        {...props}
      >
        {buttonIcon}
        {buttonText}
      </Button>
    </Tooltip>
  )
}

export default SaveButton
