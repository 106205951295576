import { intersection, isSuperset } from 'utils/sets'

export const applyFilters = (data, filters) => {
  const ids =
    data.allIds &&
    data.allIds.filter(id => {
      const contentPublisher = data.byId[id]
      if (filters.contentTypes) {
        if (!isSuperset(contentPublisher.content, filters.contentTypes))
          return false
      }

      if (filters.categories) {
        if (!isSuperset(contentPublisher.subject, filters.categories))
          return false
      }

      if (filters.deliveryTypes) {
        if (!isSuperset(contentPublisher.delivery, filters.deliveryTypes))
          return false
      }

      if (filters.courseStandards) {
        if (!isSuperset(contentPublisher.courseStandards, filters.courseStandards)) {
          return false
        }
      }

      if (filters.languages) {
        if (!isSuperset(contentPublisher.languages, filters.languages))
          return false
      }

      if (filters.publishers) {
        if (!isSuperset(contentPublisher.publishers, filters.publishers))
          return false
      }

      if (filters.durations) {
        if (!isSuperset(contentPublisher.duration, filters.durations))
          return false
      }


      if (filters.features.length > 0) {
        // results must include ALL selected deployment types
        if (!isSuperset(contentPublisher.features, filters.features))
          return false
      }

      return true
    })

  return ids
}
