import contentPublisherReducer from './reducer'
import rootSaga from './sagas'
import {
  fetchApplications,
  fetchFeatures,
  fetchDurations,
  fetchContentTypes,
  fetchSubjectTypes,
  fetchDeliveryTypes,
  fetchLanguages,
  fetchPublishers,
  fetchCourseStandards
} from './actions'

const getLmsModule = () => ({
  id: 'contentPublisher',
  reducerMap: {
    contentPublisher: contentPublisherReducer,
  },
  sagas: [rootSaga],
  initialActions: [
    fetchApplications(),
    fetchFeatures(),
    fetchDurations(),
    fetchContentTypes(),
    fetchSubjectTypes(),
    fetchDeliveryTypes(),
    fetchLanguages(),
    fetchPublishers(),
    fetchCourseStandards()
  ],
  finalActions: [],
})

export default getLmsModule
