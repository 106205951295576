import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import { getTrialData } from 'trials/store/selectors'
import { updateTrialData } from 'trials/store/actions'
import Section from 'trials/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))

const NumberOfUsers = props => {
  const { numberOfUsers } = useSelector(getTrialData)
  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateTrialData({ numberOfUsers: e.target.value }))

  const error = !numberOfUsers

  return (
    <Section
      title='How many seats/licenses will you need?'
      error={error}
      {...props}
    >
      <RadioGroup value={numberOfUsers || ''} onChange={handleChange}>
        <MuiFormControlLabel
          value='1-999'
          label='1-999'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='1000-5000'
          label='1,000-5,000'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='5000-15000'
          label='5,000-15,000'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='15000-50000'
          label='15,000-50,000'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='50000+'
          label='50,000+'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='unsure'
          label='Not Sure'
          control={<MuiRadio />}
        />
      </RadioGroup>
    </Section>
  )
}

export default NumberOfUsers
